.ax-alert-card {
  display: flex;
}

.ax-alert-card__icon {
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  border-right: 0.0625rem solid rgba(63, 63, 63, .2);
  border-right: var(--component-border-width) solid var(--color-theme-border);
}

.ax-alert-card__remove {
  flex: 0 0 auto;
  -ms-grid-row-align: center;
      align-self: center;
}

.ax-alert-card__content {
  flex: 1 1 0%;
  -ms-grid-row-align: center;
      align-self: center;
  color: rgba(63, 63, 63, .75);
  color: var(--color-theme-text--subtle);
}

.ax-alert-card--small .ax-alert-card__icon {
  margin-right: 0.75rem;
  margin-right: var(--spacing-grid-size--x3);
  padding-right: 0.75rem;
  padding-right: var(--spacing-grid-size--x3);
}

.ax-alert-card--small .ax-alert-card__remove {
  margin-left: 0.75rem;
  margin-left: var(--spacing-grid-size--x3);
}

.ax-alert-card--medium .ax-alert-card__icon {
  margin-right: 1rem;
  margin-right: var(--spacing-grid-size--x4);
  padding-right: 1rem;
  padding-right: var(--spacing-grid-size--x4);
}

.ax-alert-card--medium .ax-alert-card__remove {
  margin-left: 1rem;
  margin-left: var(--spacing-grid-size--x4);
}

.ax-alert-card--large .ax-alert-card__icon {
  margin-right: 1.5rem;
  margin-right: var(--spacing-grid-size--x6);
  padding-right: 1.5rem;
  padding-right: var(--spacing-grid-size--x6);
}

.ax-alert-card--large .ax-alert-card__remove {
  margin-left: 1.5rem;
  margin-left: var(--spacing-grid-size--x6);
}
