.ax-alert-dialog {
  width: 25rem;
  max-width: 100%;
  border-radius: 0.1875rem;
  border-radius: var(--component-border-radius);
  box-shadow: 0 0 0 0.0625rem rgba(63, 63, 63, .2), 0 0.25rem 0.5625rem 0 rgba(63, 63, 63, .14),
    0 0.0625rem 0.125rem 0.03125rem rgba(63, 63, 63, .15);
  box-shadow: var(--drop-shadow-theme-border), var(--drop-shadow-theme-elevation--x2);
  background-color: rgb(255, 255, 255);
  background-color: var(--color-theme-background);
}

.ax-alert-dialog__header {
  border-top-left-radius: 0.1875rem;
  border-top-left-radius: var(--component-border-radius);
  border-top-right-radius: 0.1875rem;
  border-top-right-radius: var(--component-border-radius);
  overflow: hidden;
}

.ax-alert-dialog__body {
  padding: 1.25rem 1.5rem;
  padding: var(--spacing-grid-size--x5) var(--spacing-grid-size--x6);
}
