.ax-alert-bar {
  padding-right: 1.5rem;
  padding-right: var(--page-padding-horizontal);
  padding-left: 1.5rem;
  padding-left: var(--page-padding-horizontal);
}

.ax-alert-bar--small {
  padding-top: 0.5rem;
  padding-top: var(--spacing-grid-size--x2);
  padding-bottom: 0.5rem;
  padding-bottom: var(--spacing-grid-size--x2);
}

.ax-alert-bar--medium {
  padding-top: 0.75rem;
  padding-top: var(--spacing-grid-size--x3);
  padding-bottom: 0.75rem;
  padding-bottom: var(--spacing-grid-size--x3);
}

.ax-alert-bar--success { background-color: rgb(0, 190, 150); background-color: var(--color-ui-success); }
.ax-alert-bar--warning { background-color: rgb(218, 174, 69); background-color: var(--color-ui-warning); }
.ax-alert-bar--error   { background-color: rgb(252, 72, 112); background-color: var(--color-ui-error); }
.ax-alert-bar--info    { background-color: rgb(35, 158, 219); background-color: var(--color-ui-info); }
