.ax-date-picker__day {
  display: block;
  position: relative;
  padding: 0 0.25rem;
  padding: 0 var(--spacing-grid-size);
  outline: 0;
  border: 0;
  background-color: transparent;
  color: rgba(63, 63, 63, .75);
  color: var(--color-theme-text--subtle);
  cursor: pointer
}

.ax-date-picker__day:disabled {
  color: rgba(63, 63, 63, .3);
  color: var(--color-theme-text--disabled);
  cursor: default;
}

.ax-date-picker__day-inner {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  width: var(--component-round-size-small);
  height: 1.5rem;
  height: var(--component-round-size-small);
  border-radius: 1.5rem;
  border-radius: var(--component-round-size-small);
  font-size: 0.75rem;
  font-size: var(--font-size-small);
  font-weight: 700;
  font-weight: var(--font-weight-bold);
  letter-spacing: 0.001875rem;
  letter-spacing: var(--letter-spacing-loose);
  line-height: 1rem;
  line-height: var(--component-line-height);
}

.ax-date-picker__day--selection {
  color: rgb(22, 145, 207);
  color: var(--color-ui-accent--active)
}

.ax-date-picker__day--selection::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(63, 63, 63, .15);
  background-color: var(--color-theme-background--subtle)
}

.ax-date-picker__day--first.ax-date-picker__day--selection::before {
  border-top-left-radius: 1.5rem;
  border-top-left-radius: var(--component-round-size-small);
  border-bottom-left-radius: 1.5rem;
  border-bottom-left-radius: var(--component-round-size-small);
}

.ax-date-picker__day--last.ax-date-picker__day--selection::before {
  border-top-right-radius: 1.5rem;
  border-top-right-radius: var(--component-round-size-small);
  border-bottom-right-radius: 1.5rem;
  border-bottom-right-radius: var(--component-round-size-small);
}

.ax-date-picker__day--selected {
  color: rgb(248, 248, 248);
  color: var(--color-ui-white-noise)
}

.ax-date-picker__day--selected::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
}

.ax-date-picker__day--selected .ax-date-picker__day-inner {
  background-color: rgb(22, 145, 207);
  background-color: var(--color-ui-accent--active);
}

.ax-date-picker__day--selected-end:not(.ax-date-picker__day--first)::before {
  right: 50%;
  left: 0;
  background-color: rgba(63, 63, 63, .15);
  background-color: var(--color-theme-background--subtle);
}

.ax-date-picker__day--selected-start:not(.ax-date-picker__day--last)::before {
  right: 0;
  left: 50%;
  background-color: rgba(63, 63, 63, .15);
  background-color: var(--color-theme-background--subtle);
}
