.ax-textarea {
  padding: 0.4375rem 0.75rem;
  padding: var(--component-padding-vertical-medium) var(--component-padding-horizontal-small);
  border: 0;
  border-radius: 0.1875rem;
  border-radius: var(--component-border-radius);
  background-color: rgb(255, 255, 255);
  background-color: var(--color-theme-background);
  resize: none;
  transition-property: border-color;
  transition-duration: 200ms;
  transition-duration: var(--transition-time-base);
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition-timing-function: var(--transition-function)
}

.ax-textarea:focus {
  border-color: rgba(35, 158, 219, .5);
  border-color: rgba(var(--rgb-ui-accent), var(--opacity-input-border--focused));
}

.ax-textarea:disabled {
  background-color: rgba(63, 63, 63, .05);
  background-color: var(--color-theme-background--disabled);
}

.ax-textarea--valid:not(:focus)  {
  border-color: rgba(252, 72, 112, .4);
  border-color: rgba(var(--rgb-ui-error), var(--opacity-input-border--valid));
}

.ax-textarea--invalid:not(:focus) {
  border-color: rgba(252, 72, 112, .25);
  border-color: rgba(var(--rgb-ui-error), var(--opacity-input-border--invalid));
  color: rgb(252, 72, 112);
  color: var(--color-ui-error)
}

.ax-textarea--invalid:not(:focus):-ms-input-placeholder {
  color: rgb(252, 72, 112);
  color: var(--color-ui-error);
}

.ax-textarea--invalid:not(:focus)::-ms-input-placeholder {
  color: rgb(252, 72, 112);
  color: var(--color-ui-error);
}

.ax-textarea--invalid:not(:focus)::placeholder {
  color: rgb(252, 72, 112);
  color: var(--color-ui-error);
}

.ax-textarea__progress {
  -ms-grid-row-align: start;
      align-self: start;
  padding-top: 0.75rem;
  padding-top: var(--component-padding-horizontal-small);
  padding-right: 0.75rem;
  padding-right: var(--component-padding-horizontal-small);
}
