.ax-candytar {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.ax-candytar--tiny-clanger .ax-candytar__body,
  .ax-candytar--tiny-clanger .ax-candytar__background {
  fill: rgb(245, 152, 183);
  fill: var(--color-product-tiny-clanger);
}

.ax-candytar--critical-mass .ax-candytar__body,
  .ax-candytar--critical-mass .ax-candytar__background {
  fill: rgb(227, 90, 122);
  fill: var(--color-product-critical-mass);
}

.ax-candytar--paradise-lost .ax-candytar__body,
  .ax-candytar--paradise-lost .ax-candytar__background {
  fill: rgb(144, 107, 167);
  fill: var(--color-product-paradise-lost);
}

.ax-candytar--serene-sea .ax-candytar__body,
  .ax-candytar--serene-sea .ax-candytar__background {
  fill: rgb(144, 160, 214);
  fill: var(--color-product-serene-sea);
}

.ax-candytar--giant-leap .ax-candytar__body,
  .ax-candytar--giant-leap .ax-candytar__background {
  fill: rgb(85, 205, 230);
  fill: var(--color-product-giant-leap);
}

.ax-candytar--moon-lagoon .ax-candytar__body,
  .ax-candytar--moon-lagoon .ax-candytar__background {
  fill: rgb(36, 173, 189);
  fill: var(--color-product-moon-lagoon);
}

.ax-candytar--terra-form .ax-candytar__body,
  .ax-candytar--terra-form .ax-candytar__background {
  fill: rgb(138, 197, 57);
  fill: var(--color-product-terra-form);
}

.ax-candytar--primeval-soup .ax-candytar__body,
  .ax-candytar--primeval-soup .ax-candytar__background {
  fill: rgb(200, 200, 25);
  fill: var(--color-product-primeval-soup);
}

.ax-candytar--new-horizon .ax-candytar__body,
  .ax-candytar--new-horizon .ax-candytar__background {
  fill: rgb(255, 190, 10);
  fill: var(--color-product-new-horizon);
}

.ax-candytar--blast-off .ax-candytar__body,
  .ax-candytar--blast-off .ax-candytar__background {
  fill: rgb(249, 145, 50);
  fill: var(--color-product-blast-off);
}

.ax-candytar--ground-control .ax-candytar__body,
  .ax-candytar--ground-control .ax-candytar__background {
  fill: rgb(158, 97, 57);
  fill: var(--color-product-ground-control);
}

.ax-candytar--luna-dust .ax-candytar__body,
  .ax-candytar--luna-dust .ax-candytar__background {
  fill: rgb(120, 144, 156);
  fill: var(--color-product-luna-dust);
}
