@keyframes ax-animicon-tick {
  100%{ stroke-dashoffset: 0; }
}

.ax-animicon--tick {
  stroke-dasharray: 14;
  stroke-dashoffset: 14;
  animation-duration: 200ms;
  animation-duration: var(--transition-time-base);
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  animation-timing-function: var(--transition-function);
}

.ax-animicon--tick-in {
  animation-name: ax-animicon-tick;
}

@keyframes ax-animicon-cross {
  100%{ stroke-dashoffset: 0; }
}

.ax-animicon--cross {
  stroke-dasharray: 25;
  stroke-dashoffset: 25;
  animation-duration: 400ms;
  animation-duration: var(--transition-time-slow);
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  animation-timing-function: var(--transition-function);
}

.ax-animicon--cross-in {
  animation-name: ax-animicon-cross;
}
