.ax-table {
  width: 100%;
  border-spacing: 0;
}

.ax-table__cell {
  padding: 0.5rem 1rem;
  padding: var(--spacing-grid-size--x2) var(--spacing-grid-size--x4);
  border-top: 0.0625rem dotted rgba(63, 63, 63, .2);
  border-top: var(--component-border-width-medium) dotted var(--color-theme-border);
  transition-property: color, border-color;
  transition-duration: 200ms;
  transition-duration: var(--transition-time-base);
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition-timing-function: var(--transition-function);
}

.ax-table__row--clickable {
  cursor: pointer;
}

.ax-table__row--borderless .ax-table__cell {
  border-top-width: 0;
}

.ax-table__row:first-child .ax-table__cell {
  border-top-width: 0.0625rem;
  border-top-width: var(--component-border-width-medium);
  border-top-style: solid;
}

.ax-table__row:last-child .ax-table__cell {
  border-bottom: 0.0625rem dotted rgba(63, 63, 63, .2);
  border-bottom: var(--component-border-width-medium) dotted var(--color-theme-border);
}

.ax-table__cell--align-left {
  text-align: left;
}

.ax-table__cell--align-right {
  text-align: right;
}

.ax-table__cell--horizontal-padding-none {
  padding-top: 0;
  padding-bottom: 0;
}

.ax-table__cell--horizontal-padding-medium {
  padding-top: 1rem;
  padding-top: var(--spacing-grid-size--x4);
  padding-bottom: 1rem;
  padding-bottom: var(--spacing-grid-size--x4);
}

.ax-table__cell--vertical-padding-none {
  padding-top: 0;
  padding-bottom: 0;
}

.ax-table__cell--vertical-padding-small {
  padding-top: 0.5rem;
  padding-top: var(--spacing-grid-size--x2);
  padding-bottom: 0.5rem;
  padding-bottom: var(--spacing-grid-size--x2);
}

.ax-table__cell--vertical-padding-medium {
  padding-top: 0.75rem;
  padding-top: var(--spacing-grid-size--x3);
  padding-bottom: 0.75rem;
  padding-bottom: var(--spacing-grid-size--x3);
}

.ax-table__cell--vertical-padding-large {
  padding-top: 1rem;
  padding-top: var(--spacing-grid-size--x4);
  padding-bottom: 1rem;
  padding-bottom: var(--spacing-grid-size--x4);
}

.ax-table__header-button {
  display: inline-flex;
  position: relative;
  align-items: center;
  padding: 0;
  outline: 0;
  border: none;
  background: none;
  color: rgba(63, 63, 63, .75);
  color: var(--color-theme-text--subtle);
  cursor: pointer;
  transition: color 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: color var(--transition-time-base) var(--transition-function);
}

.ax-table__cell--selected {
  color: rgb(63, 63, 63);
  color: var(--color-theme-text);
}

.ax-table__header-button:disabled {
  cursor: auto;
}

.ax-table__header-label--align-right .ax-table__header-button {
  flex-direction: row-reverse;
}

.ax-table__header-label--selected .ax-table__header-button {
  color: rgb(63, 63, 63);
  color: var(--color-theme-text);
}

.ax-table__header-button::after {
  content: '';
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  transform: scaleX(0) translateY(0.5rem);
  transform: scaleX(0) translateY(var(--spacing-grid-size--x2));
  border-bottom: 0.25rem solid rgb(35, 158, 219);
  border-bottom: var(--spacing-grid-size--x1) solid var(--color-ui-accent);
  transition: transform 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: transform var(--transition-time-base) var(--transition-function);
}

.ax-table__header-label--selected .ax-table__header-button::after {
  transform: scaleX(1) translateY(0.5rem);
  transform: scaleX(1) translateY(var(--spacing-grid-size--x2));
}

.ax-table__header-button:hover:not(:disabled) {
  color: rgb(48, 171, 232);
  color: var(--color-ui-accent--hover);
}

.ax-table__header-label {
  padding: 0.5rem 1rem;
  padding: var(--spacing-grid-size--x2) var(--spacing-grid-size--x4);
  color: rgba(63, 63, 63, .75);
  color: var(--color-theme-text--subtle);
  font-weight: 700;
  font-weight: var(--font-weight-bold);
  white-space: nowrap;
  vertical-align: bottom;
  transition: color 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: color var(--transition-time-base) var(--transition-function);
}

.ax-table__header:only-child .ax-table__header-label {
  border-bottom: 0.0625rem solid rgba(63, 63, 63, .2);
  border-bottom: var(--component-border-width-medium) solid var(--color-theme-border);
}

.ax-table__header-label--align-left {
  text-align: left;
}

.ax-table__header-label--align-right {
  text-align: right;
}

.ax-table__header-label--grow {
  width: 100%;
}

.ax-table__header-label--selected {
  color: rgb(63, 63, 63);
  color: var(--color-theme-text);
}

.ax-table__header-label--shrink {
  width: 1%;
}

.ax-table__row {
  transition-property: background-color;
  transition-duration: 200ms;
  transition-duration: var(--transition-time-base);
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition-timing-function: var(--transition-function);
}

.ax-table__row.ax-table__row--hover,
.ax-table__row:hover {
  background-color: rgba(63, 63, 63, .04);
  background-color: var(--color-theme-background--hover);
}
