.ax-input,
.ax-textarea {
  width: 100%;
  min-width: 0;
  color: inherit;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none
}

.ax-input:-ms-input-placeholder, .ax-textarea:-ms-input-placeholder {
  opacity: 1;
  color: rgba(63, 63, 63, .3);
  color: var(--color-theme-text--disabled);
}

.ax-input::-ms-input-placeholder, .ax-textarea::-ms-input-placeholder {
  opacity: 1;
  color: rgba(63, 63, 63, .3);
  color: var(--color-theme-text--disabled);
}

.ax-input::placeholder, .ax-textarea::placeholder {
  opacity: 1;
  color: rgba(63, 63, 63, .3);
  color: var(--color-theme-text--disabled);
}

.ax-input:focus, .ax-textarea:focus {
  outline: none
}

.ax-input:focus:-ms-input-placeholder, .ax-textarea:focus:-ms-input-placeholder {
  color: transparent;
}

.ax-input:focus::-ms-input-placeholder, .ax-textarea:focus::-ms-input-placeholder {
  color: transparent;
}

.ax-input:focus::placeholder, .ax-textarea:focus::placeholder {
  color: transparent;
}

.ax-input__wrapper {
  display: flex;
  position: relative;
  flex: 1 1 auto;
  flex-direction: column;
}

.ax-input__wrapper--inline {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ax-input__wrapper--inline .ax-input__icon-container {
  margin-left: 0.5rem;
  margin-left: var(--spacing-grid-size--x2);
}

.ax-input__hint-wrapper {
  display: flex;
  justify-content: flex-end;
}

.ax-input__hint-wrapper .ax-input__wrapper--label {
  flex-grow: 1;
}

.ax-input__hint {
  flex-shrink: 0;
}

.ax-input__wrapper--inline .ax-input__hint-wrapper {
  align-items: center;
}

.ax-input__wrapper--inline .ax-input__hint {
  margin-left: 0.25rem;
  margin-left: var(--spacing-grid-size--x1);
}

.ax-input__wrapper--target .ax-input {
  cursor: pointer;
}
