:root {
  --ax-toggle-knob-color: rgb(255, 255, 255);
  --ax-toggle-knob-color: var(--color-ui-white);
  --ax-toggle-border-width: 0.0625rem;
  --ax-toggle-size--small: 1rem;
  --ax-toggle-size--small: var(--spacing-grid-size--x4);
  --ax-toggle-size--medium: 1.5rem;
  --ax-toggle-size--medium: var(--spacing-grid-size--x6);
  --ax-toggle-diameter--small: 0.875rem;
  --ax-toggle-diameter--small: calc(var(--ax-toggle-size--small) - var(--ax-toggle-border-width) * 2);
  --ax-toggle-diameter--medium: 1.375rem;
  --ax-toggle-diameter--medium: calc(var(--ax-toggle-size--medium) - var(--ax-toggle-border-width) * 2);
}

.ax-toggle {
  display: flex;
  align-items: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.ax-toggle--enabled {
  cursor: pointer;
}

.ax-toggle__switch {
  display: flex;
  position: relative;
}

.ax-toggle__label {
  margin-right: 0.5rem;
  margin-right: calc(var(--spacing-grid-size) * 2);
}

.ax-toggle__input {
  position: absolute;
  opacity: 0;
  cursor: inherit;
}

.ax-toggle__appearance {
  background-color: rgba(63, 63, 63, .15);
  background-color: var(--color-theme-background--subtle);
  transition: background-color 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: background-color var(--transition-time-base) var(--transition-function)
}

.ax-toggle__appearance::after {
  content: '';
  position: absolute;
  top: 0.0625rem;
  top: var(--ax-toggle-border-width);
  left: 0.0625rem;
  left: var(--ax-toggle-border-width);
  border: 0.0625rem solid rgb(255, 255, 255);
  border: var(--ax-toggle-border-width) solid var(--ax-toggle-knob-color);
  border-radius: 50%;
  box-shadow: 0.1rem 0 0.25rem 0 rgba(53, 53, 53, .3);
  box-shadow: 0.1rem 0 0.25rem 0 rgba(var(--rgb-ui-carbon--dark), 0.3);
  background-color: rgb(255, 255, 255);
  background-color: var(--ax-toggle-knob-color);
  transition-property: background-color, border-color, box-shadow, transform;
  transition-duration: 200ms;
  transition-duration: var(--transition-time-base);
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition-timing-function: var(--transition-function);
}

.ax-toggle--small .ax-toggle__input,
  .ax-toggle--small .ax-toggle__appearance {
  width: 2rem;
  width: calc(var(--ax-toggle-size--small) * 2);
  height: 1rem;
  height: var(--ax-toggle-size--small);
  border-radius: 1rem;
  border-radius: var(--ax-toggle-size--small);
}

.ax-toggle--small .ax-toggle__appearance::after {
  width: 0.875rem;
  width: var(--ax-toggle-diameter--small);
  height: 0.875rem;
  height: var(--ax-toggle-diameter--small);
}

.ax-toggle--medium .ax-toggle__input,
  .ax-toggle--medium .ax-toggle__appearance {
  width: 3rem;
  width: calc(var(--ax-toggle-size--medium) * 2);
  height: 1.5rem;
  height: var(--ax-toggle-size--medium);
  border-radius: 1.5rem;
  border-radius: var(--ax-toggle-size--medium);
}

.ax-toggle--medium .ax-toggle__appearance::after {
  width: 1.375rem;
  width: var(--ax-toggle-diameter--medium);
  height: 1.375rem;
  height: var(--ax-toggle-diameter--medium);
}

.ax-toggle--align-left {
  justify-content: flex-start;
}

.ax-toggle--align-right {
  justify-content: flex-end;
}

.ax-toggle--align-full {
  justify-content: space-between;
}

.ax-toggle__input:checked + .ax-toggle__appearance {
  background-color: rgb(35, 158, 219);
  background-color: var(--color-ui-accent);
}

.ax-toggle__input:checked + .ax-toggle__appearance::after {
  transform: translateX(calc(100% + 0.125rem));
  transform: translateX(calc(100% + var(--ax-toggle-border-width) * 2));
  box-shadow: 0 0 0.25rem 0 rgba(53, 53, 53, .3);
  box-shadow: 0 0 0.25rem 0 rgba(var(--rgb-ui-carbon--dark), 0.3);
}

.ax-toggle__input:disabled + .ax-toggle__appearance {
  background-color: rgba(63, 63, 63, .15);
  background-color: var(--color-theme-background--subtle);
}

.ax-toggle__input:disabled + .ax-toggle__appearance::after {
  border-color: rgba(63, 63, 63, .2);
  border-color: var(--color-theme-border);
  box-shadow: 0 0 0.25rem 0 rgba(53, 53, 53, 0);
  box-shadow: 0 0 0.25rem 0 rgba(var(--rgb-ui-carbon--dark), 0);
  background-color: transparent;
}
