.ax-image {
  display: block;
  margin-right: auto;
  margin-left: auto;
  border: 0 solid rgba(63, 63, 63, .2);
  border: 0 solid var(--color-theme-border);
}

.ax-image--circle  { border-radius: 50%; }
.ax-image--rounded { border-radius: 0.1875rem; border-radius: var(--component-border-radius); }
.ax-image--square  { border-radius: 0; }

.ax-image--border-small { border-width: 0.0625rem; border-width: var(--component-border-width-small); }
.ax-image--border-large { border-width: 0.125rem; border-width: var(--component-border-width-large); }
