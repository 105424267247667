.ax-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  outline: 0;
  border-width: 0.0625rem;
  border-width: var(--component-border-width);
  border-style: solid;
  border-radius: 0.1875rem;
  border-radius: var(--component-border-radius);
  border-color: transparent;
  background-color: transparent;
  font-weight: 700;
  font-weight: var(--font-weight-bold);
  letter-spacing: 0.001875rem;
  letter-spacing: var(--letter-spacing-loose);
  text-align: center;
  text-transform: uppercase;
  line-height: 1rem;
  line-height: var(--component-line-height);
  white-space: nowrap;
  vertical-align: middle;
  transition-property: background-color, border-color, color;
  transition-duration: 200ms;
  transition-duration: var(--transition-time-base);
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition-timing-function: var(--transition-function)
}

.ax-button:hover {
  cursor: pointer;
}

.ax-button[disabled],
  .ax-button[disabled]:hover,
  .ax-button[disabled]:active {
  cursor: default;
}

.ax-button {

  /* Works around flex alignment bug in Safari */
}

.ax-button::before,
  .ax-button::after {
  content: '';
  flex: 1 0 auto;
}

.ax-button--joined-left {
  border-left-width: 0;
}

.ax-button--joined:not(:first-child),
.ax-button--joined-left {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.ax-button--joined:not(:last-child),
.ax-button--joined-right {
  margin-right: 0;
  border-right-width: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ax-button--full {
  width: 100%;
}

@media (max-width: 47.9375rem) {
  .ax-button--full--small { width: 100%; }
}

@media (max-width: 61.9375rem) {
  .ax-button--full--medium { width: 100%; }
}

@media (max-width: 74.9375rem) {
  .ax-button--full--large { width: 100%; }
}

.ax-button--circle-small,
.ax-button--circle-medium,
.ax-button--circle-large,
.ax-button--circle-huge {
  padding: 0;
  border-radius: 50%;
  line-height: 0;
}

.ax-button--circle-small {
  width: 1.5rem;
  width: var(--component-round-size-small);
  height: 1.5rem;
  height: var(--component-round-size-small);
  border-width: 0.0625rem;
  border-width: var(--component-border-width-small);
}

.ax-button--circle-medium {
  width: 3rem;
  width: var(--component-round-size-medium);
  height: 3rem;
  height: var(--component-round-size-medium);
  border-width: 0.0625rem;
  border-width: var(--component-border-width-medium);
}

.ax-button--circle-large {
  width: 4.5rem;
  width: var(--component-round-size-large);
  height: 4.5rem;
  height: var(--component-round-size-large);
  border-width: 0.125rem;
  border-width: var(--component-border-width-large);
}

.ax-button--circle-huge {
  width: 7.5rem;
  width: var(--component-round-size-huge);
  height: 7.5rem;
  height: var(--component-round-size-huge);
  border-width: 0.125rem;
  border-width: var(--component-border-width-huge);
}

.ax-button--rectangle-small {
  padding: 0.1875rem 0.75rem;
  padding: var(--component-padding-vertical-small) var(--component-padding-horizontal-small);
  font-size: 0.75rem;
  font-size: var(--font-size-small);
}

.ax-button--rectangle-medium {
  padding: 0.4375rem 0.75rem;
  padding: var(--component-padding-vertical-medium) var(--component-padding-horizontal-medium);
  font-size: 0.75rem;
  font-size: var(--font-size-small);
}

.ax-button--rectangle-large {
  padding: 0.6875rem 1rem;
  padding: var(--component-padding-vertical-large) var(--component-padding-horizontal-large);
  font-size: 0.875rem;
  font-size: var(--font-size-large);
}

.ax-button--icon-only.ax-button--rectangle-small {
  padding-right: 0.1875rem;
  padding-right: var(--component-padding-vertical-small);
  padding-left: 0.1875rem;
  padding-left: var(--component-padding-vertical-small);
}

.ax-button--icon-only.ax-button--rectangle-medium {
  padding-right: 0.4375rem;
  padding-right: var(--component-padding-vertical-medium);
  padding-left: 0.4375rem;
  padding-left: var(--component-padding-vertical-medium);
}

.ax-button--icon-only.ax-button--rectangle-large {
  padding-right: 0.6875rem;
  padding-right: var(--component-padding-vertical-large);
  padding-left: 0.6875rem;
  padding-left: var(--component-padding-vertical-large);
}

.ax-button--stadium-small {
  min-width: 1.5rem;
  min-width: var(--component-round-size-small);
  height: 1.5rem;
  height: var(--component-round-size-small);
  padding: 0 0.375rem;
  padding: 0 calc(var(--component-round-size-small) * 0.25);
  border-width: 0.0625rem;
  border-width: var(--component-border-width-small);
  border-radius: 1.5rem;
  border-radius: var(--component-round-size-small);
  line-height: 0;
}

.ax-button--primary {
  border-color: transparent;
  color: rgb(248, 248, 248);
  color: var(--color-ui-white-noise)

  /* Pattern obscurity */
}

.ax-button--primary.ax-button--joined:not(:first-child) {
  border-left-color: rgba(238, 238, 238, .45);
  border-left-color: rgba(var(--rgb-ui-white-noise--dark), 0.45);
}

.ax-button--primary:disabled {
  background-color: rgba(63, 63, 63, .05);
  background-color: var(--color-theme-background--disabled);
  color: rgba(63, 63, 63, .3);
  color: var(--color-theme-text--disabled);
}

.ax-button--primary:not(:disabled):active,
  .ax-button--primary:not(:disabled).ax-button--active {
  color: rgb(248, 248, 248);
  color: var(--color-ui-white-noise);
}

.ax-button--secondary {
  border-color: rgba(63, 63, 63, .2);
  border-color: var(--color-theme-border)
}

.ax-button--secondary:hover {
  border-color: transparent;
  background-color: rgba(63, 63, 63, .15);
  background-color: var(--color-theme-background--subtle);
}

.ax-button--secondary:disabled {
  border-color: rgba(63, 63, 63, .2);
  border-color: var(--color-theme-border);
  background-color: transparent;
  color: rgba(63, 63, 63, .3);
  color: var(--color-theme-text--disabled);
}

.ax-button--secondary:not(:disabled):active,
  .ax-button--secondary:not(:disabled).ax-button--active {
  border-color: transparent;
  background-color: rgba(63, 63, 63, .15);
  background-color: var(--color-theme-background--subtle);
}

.ax-button--tertiary:disabled {
  color: rgba(63, 63, 63, .3);
  color: var(--color-theme-text--disabled);
}

.ax-button--quaternary {
  color: rgba(63, 63, 63, .75);
  color: var(--color-theme-text--subtle)
}

.ax-button--quaternary:hover {
  color: rgb(48, 171, 232);
  color: var(--color-ui-accent--hover);
}

.ax-button--quaternary:disabled {
  color: rgba(63, 63, 63, .3);
  color: var(--color-theme-text--disabled);
}

.ax-button--quaternary:not(:disabled):active,
  .ax-button--quaternary:not(:disabled).ax-button--active {
  color: rgb(22, 145, 207);
  color: var(--color-ui-accent--active);
}

.ax-button--accent:not(:disabled).ax-button--primary {
  background-color: rgb(35, 158, 219);
  background-color: var(--color-ui-accent)
}

.ax-button--accent:not(:disabled).ax-button--primary:hover  {
  background-color: rgb(48, 171, 232);
  background-color: var(--color-ui-accent--hover);
}

.ax-button--accent:not(:disabled).ax-button--primary:active,
    .ax-button--accent:not(:disabled).ax-button--primary.ax-button--active {
  background-color: rgb(22, 145, 207);
  background-color: var(--color-ui-accent--active);
}

.ax-button--accent:not(:disabled).ax-button--secondary,
  .ax-button--accent:not(:disabled).ax-button--tertiary {
  color: rgb(35, 158, 219);
  color: var(--color-ui-accent);
}

.ax-button--accent:not(:disabled).ax-button--secondary:hover, .ax-button--accent:not(:disabled).ax-button--tertiary:hover, .ax-button--accent:not(:disabled).ax-button--quaternary:hover {
  color: rgb(48, 171, 232);
  color: var(--color-ui-accent--hover);
}

.ax-button--accent:not(:disabled).ax-button--secondary:active,
    .ax-button--accent:not(:disabled).ax-button--secondary.ax-button--active,
    .ax-button--accent:not(:disabled).ax-button--tertiary:active,
    .ax-button--accent:not(:disabled).ax-button--tertiary.ax-button--active,
    .ax-button--accent:not(:disabled).ax-button--quaternary:active,
    .ax-button--accent:not(:disabled).ax-button--quaternary.ax-button--active {
  color: rgb(22, 145, 207);
  color: var(--color-ui-accent--active);
}

.ax-button--negative:not(:disabled).ax-button--primary {
  background-color: rgb(252, 72, 112);
  background-color: var(--color-ui-error)
}

.ax-button--negative:not(:disabled).ax-button--primary:hover  {
  background-color: rgb(255, 99, 133);
  background-color: var(--color-ui-error--hover);
}

.ax-button--negative:not(:disabled).ax-button--primary:active,
    .ax-button--negative:not(:disabled).ax-button--primary.ax-button--active {
  background-color: rgb(240, 58, 97);
  background-color: var(--color-ui-error--active);
}

.ax-button--negative:not(:disabled).ax-button--secondary,
  .ax-button--negative:not(:disabled).ax-button--tertiary {
  color: rgb(252, 72, 112);
  color: var(--color-ui-error);
}

.ax-button--negative:not(:disabled).ax-button--secondary:hover, .ax-button--negative:not(:disabled).ax-button--tertiary:hover, .ax-button--negative:not(:disabled).ax-button--quaternary:hover {
  color: rgb(255, 99, 133);
  color: var(--color-ui-error--hover);
}

.ax-button--negative:not(:disabled).ax-button--secondary:active,
    .ax-button--negative:not(:disabled).ax-button--secondary.ax-button--active,
    .ax-button--negative:not(:disabled).ax-button--tertiary:active,
    .ax-button--negative:not(:disabled).ax-button--tertiary.ax-button--active,
    .ax-button--negative:not(:disabled).ax-button--quaternary:active,
    .ax-button--negative:not(:disabled).ax-button--quaternary.ax-button--active {
  color: rgb(240, 58, 97);
  color: var(--color-ui-error--active);
}

.ax-button--positive:not(:disabled).ax-button--primary {
  background-color: rgb(0, 190, 150);
  background-color: var(--color-ui-success)
}

.ax-button--positive:not(:disabled).ax-button--primary:hover  {
  background-color: rgb(10, 204, 162);
  background-color: var(--color-ui-success--hover);
}

.ax-button--positive:not(:disabled).ax-button--primary:active,
    .ax-button--positive:not(:disabled).ax-button--primary.ax-button--active {
  background-color: rgb(0, 166, 130);
  background-color: var(--color-ui-success--active);
}

.ax-button--positive:not(:disabled).ax-button--secondary,
  .ax-button--positive:not(:disabled).ax-button--tertiary {
  color: rgb(0, 190, 150);
  color: var(--color-ui-success);
}

.ax-button--positive:not(:disabled).ax-button--secondary:hover, .ax-button--positive:not(:disabled).ax-button--tertiary:hover, .ax-button--positive:not(:disabled).ax-button--quaternary:hover {
  color: rgb(10, 204, 162);
  color: var(--color-ui-success--hover);
}

.ax-button--positive:not(:disabled).ax-button--secondary:active,
    .ax-button--positive:not(:disabled).ax-button--secondary.ax-button--active,
    .ax-button--positive:not(:disabled).ax-button--tertiary:active,
    .ax-button--positive:not(:disabled).ax-button--tertiary.ax-button--active,
    .ax-button--positive:not(:disabled).ax-button--quaternary:active,
    .ax-button--positive:not(:disabled).ax-button--quaternary.ax-button--active {
  color: rgb(0, 166, 130);
  color: var(--color-ui-success--active);
}
