.ax-badge {
  display: inline-flex;
  position: relative;
  padding: 0 0.5rem;
  padding: 0 calc(var(--component-line-height) * 0.5);
  border-radius: 1rem;
  border-radius: var(--component-line-height);
  font-size: 0.75rem;
  font-size: var(--font-size-small);
  line-height: 1rem;
  line-height: var(--component-line-height);
  white-space: nowrap;
  vertical-align: middle;
  overflow: hidden;
}

.ax-badge--full {
  width: 100%;
}

@media (max-width: 47.9375rem) {
  .ax-badge--full--small { width: 100%; }
}

@media (max-width: 61.9375rem) {
  .ax-badge--full--medium { width: 100%; }
}

@media (max-width: 74.9375rem) {
  .ax-badge--full--large { width: 100%; }
}

.ax-badge--clickable {
  cursor: pointer;
}

.ax-badge__content {
  position: relative;
  width: 100%;
}

.ax-badge__background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition-property: background-color;
  transition-duration: 200ms;
  transition-duration: var(--transition-time-base);
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition-timing-function: var(--transition-function);
}

.ax-badge--faded .ax-badge__background { background-color: rgb(63, 63, 63); background-color: var(--color-theme-text); }
.ax-badge--highlight .ax-badge__background { background-color: rgb(215, 255, 0); background-color: var(--color-ui-highlight); }
.ax-badge--success .ax-badge__background { background-color: rgb(0, 190, 150); background-color: var(--color-ui-success); }
.ax-badge--error .ax-badge__background { background-color: rgb(252, 72, 112); background-color: var(--color-ui-error); }
.ax-badge--forbidden-planet .ax-badge__background { background-color: rgb(245, 177, 164); background-color: var(--color-product-forbidden-planet); }
.ax-badge--tiny-clanger .ax-badge__background { background-color: rgb(245, 152, 183); background-color: var(--color-product-tiny-clanger); }
.ax-badge--critical-mass .ax-badge__background { background-color: rgb(227, 90, 122); background-color: var(--color-product-critical-mass); }
.ax-badge--fantastic-voyage .ax-badge__background { background-color: rgb(167, 46, 94); background-color: var(--color-product-fantastic-voyage); }
.ax-badge--paradise-lost .ax-badge__background { background-color: rgb(144, 107, 167); background-color: var(--color-product-paradise-lost); }
.ax-badge--serene-sea .ax-badge__background { background-color: rgb(144, 160, 214); background-color: var(--color-product-serene-sea); }
.ax-badge--event-horizon .ax-badge__background { background-color: rgb(96, 99, 161); background-color: var(--color-product-event-horizon); }
.ax-badge--electric-dreams .ax-badge__background { background-color: rgb(59, 121, 204); background-color: var(--color-product-electric-dreams); }
.ax-badge--outer-limits .ax-badge__background { background-color: rgb(127, 214, 248); background-color: var(--color-product-outer-limits); }
.ax-badge--giant-leap .ax-badge__background { background-color: rgb(85, 205, 230); background-color: var(--color-product-giant-leap); }
.ax-badge--moon-lagoon .ax-badge__background { background-color: rgb(36, 173, 189); background-color: var(--color-product-moon-lagoon); }
.ax-badge--space-invader .ax-badge__background { background-color: rgb(47, 145, 112); background-color: var(--color-product-space-invader); }
.ax-badge--extraterrestrial .ax-badge__background { background-color: rgb(96, 180, 90); background-color: var(--color-product-extraterrestrial); }
.ax-badge--terra-form .ax-badge__background { background-color: rgb(138, 197, 57); background-color: var(--color-product-terra-form); }
.ax-badge--primeval-soup .ax-badge__background { background-color: rgb(200, 200, 25); background-color: var(--color-product-primeval-soup); }
.ax-badge--future-shock .ax-badge__background { background-color: rgb(240, 236, 29); background-color: var(--color-product-future-shock); }
.ax-badge--sun-maker .ax-badge__background { background-color: rgb(252, 213, 18); background-color: var(--color-product-sun-maker); }
.ax-badge--new-horizon .ax-badge__background { background-color: rgb(255, 190, 10); background-color: var(--color-product-new-horizon); }
.ax-badge--blast-off .ax-badge__background { background-color: rgb(249, 145, 50); background-color: var(--color-product-blast-off); }
.ax-badge--crash-course .ax-badge__background { background-color: rgb(255, 109, 86); background-color: var(--color-product-crash-course); }
.ax-badge--solar-rust .ax-badge__background { background-color: rgb(205, 129, 26); background-color: var(--color-product-solar-rust); }
.ax-badge--ground-control .ax-badge__background { background-color: rgb(158, 97, 57); background-color: var(--color-product-ground-control); }
.ax-badge--space-oddity .ax-badge__background { background-color: rgb(189, 163, 91); background-color: var(--color-product-space-oddity); }
.ax-badge--rocky-planet .ax-badge__background { background-color: rgb(158, 142, 117); background-color: var(--color-product-rocky-planet); }
.ax-badge--deep-thought .ax-badge__background { background-color: rgb(187, 185, 192); background-color: var(--color-product-deep-thought); }
.ax-badge--luna-dust .ax-badge__background { background-color: rgb(120, 144, 156); background-color: var(--color-product-luna-dust); }

.ax-badge--success.ax-badge--clickable:hover .ax-badge__background {
  background-color: rgb(10, 204, 162);
  background-color: var(--color-ui-success--hover);
}

.ax-badge--success.ax-badge--clickable:active .ax-badge__background {
  background-color: rgb(0, 166, 130);
  background-color: var(--color-ui-success--active);
}

.ax-badge--error.ax-badge--clickable:hover .ax-badge__background {
  background-color: rgb(255, 99, 133);
  background-color: var(--color-ui-error--hover);
}

.ax-badge--error.ax-badge--clickable:active .ax-badge__background {
  background-color: rgb(240, 58, 97);
  background-color: var(--color-ui-error--active);
}

.ax-badge--forbidden-planet.ax-badge--clickable:hover .ax-badge__background {
  background-color: rgb(255, 195, 184);
  background-color: var(--color-product-forbidden-planet--hover);
}

.ax-badge--forbidden-planet.ax-badge--clickable:active .ax-badge__background {
  background-color: rgb(232, 159, 144);
  background-color: var(--color-product-forbidden-planet--active);
}

.ax-badge--tiny-clanger.ax-badge--clickable:hover .ax-badge__background {
  background-color: rgb(255, 171, 200);
  background-color: var(--color-product-tiny-clanger--hover);
}

.ax-badge--tiny-clanger.ax-badge--clickable:active .ax-badge__background {
  background-color: rgb(232, 132, 166);
  background-color: var(--color-product-tiny-clanger--active);
}

.ax-badge--critical-mass.ax-badge--clickable:hover .ax-badge__background {
  background-color: rgb(240, 108, 139);
  background-color: var(--color-product-critical-mass--hover);
}

.ax-badge--critical-mass.ax-badge--clickable:active .ax-badge__background {
  background-color: rgb(214, 75, 107);
  background-color: var(--color-product-critical-mass--active);
}

.ax-badge--fantastic-voyage.ax-badge--clickable:hover .ax-badge__background {
  background-color: rgb(181, 60, 106);
  background-color: var(--color-product-fantastic-voyage--hover);
}

.ax-badge--fantastic-voyage.ax-badge--clickable:active .ax-badge__background {
  background-color: rgb(156, 36, 82);
  background-color: var(--color-product-fantastic-voyage--active);
}

.ax-badge--paradise-lost.ax-badge--clickable:hover .ax-badge__background {
  background-color: rgb(157, 123, 179);
  background-color: var(--color-product-paradise-lost--hover);
}

.ax-badge--paradise-lost.ax-badge--clickable:active .ax-badge__background {
  background-color: rgb(129, 90, 153);
  background-color: var(--color-product-paradise-lost--active);
}

.ax-badge--serene-sea.ax-badge--clickable:hover .ax-badge__background {
  background-color: rgb(163, 178, 227);
  background-color: var(--color-product-serene-sea--hover);
}

.ax-badge--serene-sea.ax-badge--clickable:active .ax-badge__background {
  background-color: rgb(125, 143, 201);
  background-color: var(--color-product-serene-sea--active);
}

.ax-badge--event-horizon.ax-badge--clickable:hover .ax-badge__background {
  background-color: rgb(113, 116, 173);
  background-color: var(--color-product-event-horizon--hover);
}

.ax-badge--event-horizon.ax-badge--clickable:active .ax-badge__background {
  background-color: rgb(81, 85, 148);
  background-color: var(--color-product-event-horizon--active);
}

.ax-badge--electric-dreams.ax-badge--clickable:hover .ax-badge__background {
  background-color: rgb(74, 136, 217);
  background-color: var(--color-product-electric-dreams--hover);
}

.ax-badge--electric-dreams.ax-badge--clickable:active .ax-badge__background {
  background-color: rgb(46, 109, 191);
  background-color: var(--color-product-electric-dreams--active);
}

.ax-badge--outer-limits.ax-badge--clickable:hover .ax-badge__background {
  background-color: rgb(147, 222, 252);
  background-color: var(--color-product-outer-limits--hover);
}

.ax-badge--outer-limits.ax-badge--clickable:active .ax-badge__background {
  background-color: rgb(108, 199, 235);
  background-color: var(--color-product-outer-limits--active);
}

.ax-badge--giant-leap.ax-badge--clickable:hover .ax-badge__background {
  background-color: rgb(102, 219, 242);
  background-color: var(--color-product-giant-leap--hover);
}

.ax-badge--giant-leap.ax-badge--clickable:active .ax-badge__background {
  background-color: rgb(69, 192, 217);
  background-color: var(--color-product-giant-leap--active);
}

.ax-badge--moon-lagoon.ax-badge--clickable:hover .ax-badge__background {
  background-color: rgb(48, 186, 201);
  background-color: var(--color-product-moon-lagoon--hover);
}

.ax-badge--moon-lagoon.ax-badge--clickable:active .ax-badge__background {
  background-color: rgb(25, 161, 176);
  background-color: var(--color-product-moon-lagoon--active);
}

.ax-badge--space-invader.ax-badge--clickable:hover .ax-badge__background {
  background-color: rgb(58, 158, 125);
  background-color: var(--color-product-space-invader--hover);
}

.ax-badge--space-invader.ax-badge--clickable:active .ax-badge__background {
  background-color: rgb(36, 133, 100);
  background-color: var(--color-product-space-invader--active);
}

.ax-badge--extraterrestrial.ax-badge--clickable:hover .ax-badge__background {
  background-color: rgb(112, 194, 107);
  background-color: var(--color-product-extraterrestrial--hover);
}

.ax-badge--extraterrestrial.ax-badge--clickable:active .ax-badge__background {
  background-color: rgb(82, 168, 76);
  background-color: var(--color-product-extraterrestrial--active);
}

.ax-badge--terra-form.ax-badge--clickable:hover .ax-badge__background {
  background-color: rgb(151, 209, 71);
  background-color: var(--color-product-terra-form--hover);
}

.ax-badge--terra-form.ax-badge--clickable:active .ax-badge__background {
  background-color: rgb(125, 184, 44);
  background-color: var(--color-product-terra-form--active);
}

.ax-badge--primeval-soup.ax-badge--clickable:hover .ax-badge__background {
  background-color: rgb(212, 212, 38);
  background-color: var(--color-product-primeval-soup--hover);
}

.ax-badge--primeval-soup.ax-badge--clickable:active .ax-badge__background {
  background-color: rgb(186, 186, 15);
  background-color: var(--color-product-primeval-soup--active);
}

.ax-badge--future-shock.ax-badge--clickable:hover .ax-badge__background {
  background-color: rgb(252, 249, 43);
  background-color: var(--color-product-future-shock--hover);
}

.ax-badge--future-shock.ax-badge--clickable:active .ax-badge__background {
  background-color: rgb(227, 223, 16);
  background-color: var(--color-product-future-shock--active);
}

.ax-badge--sun-maker.ax-badge--clickable:hover .ax-badge__background {
  background-color: rgb(255, 220, 46);
  background-color: var(--color-product-sun-maker--hover);
}

.ax-badge--sun-maker.ax-badge--clickable:active .ax-badge__background {
  background-color: rgb(240, 201, 5);
  background-color: var(--color-product-sun-maker--active);
}

.ax-badge--new-horizon.ax-badge--clickable:hover .ax-badge__background {
  background-color: rgb(254, 199, 49);
  background-color: var(--color-product-new-horizon--hover);
}

.ax-badge--new-horizon.ax-badge--clickable:active .ax-badge__background {
  background-color: rgb(242, 178, 0);
  background-color: var(--color-product-new-horizon--active);
}

.ax-badge--blast-off.ax-badge--clickable:hover .ax-badge__background {
  background-color: rgb(255, 157, 67);
  background-color: var(--color-product-blast-off--hover);
}

.ax-badge--blast-off.ax-badge--clickable:active .ax-badge__background {
  background-color: rgb(237, 132, 36);
  background-color: var(--color-product-blast-off--active);
}

.ax-badge--crash-course.ax-badge--clickable:hover .ax-badge__background {
  background-color: rgb(255, 126, 106);
  background-color: var(--color-product-crash-course--hover);
}

.ax-badge--crash-course.ax-badge--clickable:active .ax-badge__background {
  background-color: rgb(242, 93, 70);
  background-color: var(--color-product-crash-course--active);
}

.ax-badge--solar-rust.ax-badge--clickable:hover .ax-badge__background {
  background-color: rgb(217, 143, 39);
  background-color: var(--color-product-solar-rust--hover);
}

.ax-badge--solar-rust.ax-badge--clickable:active .ax-badge__background {
  background-color: rgb(191, 118, 15);
  background-color: var(--color-product-solar-rust--active);
}

.ax-badge--ground-control.ax-badge--clickable:hover .ax-badge__background {
  background-color: rgb(171, 110, 70);
  background-color: var(--color-product-ground-control--hover);
}

.ax-badge--ground-control.ax-badge--clickable:active .ax-badge__background {
  background-color: rgb(145, 85, 45);
  background-color: var(--color-product-ground-control--active);
}

.ax-badge--space-oddity.ax-badge--clickable:hover .ax-badge__background {
  background-color: rgb(201, 176, 107);
  background-color: var(--color-product-space-oddity--hover);
}

.ax-badge--space-oddity.ax-badge--clickable:active .ax-badge__background {
  background-color: rgb(176, 149, 76);
  background-color: var(--color-product-space-oddity--active);
}

.ax-badge--rocky-planet.ax-badge--clickable:hover .ax-badge__background {
  background-color: rgb(171, 157, 135);
  background-color: var(--color-product-rocky-planet--hover);
}

.ax-badge--rocky-planet.ax-badge--clickable:active .ax-badge__background {
  background-color: rgb(145, 128, 100);
  background-color: var(--color-product-rocky-planet--active);
}

.ax-badge--deep-thought.ax-badge--clickable:hover .ax-badge__background {
  background-color: rgb(204, 204, 204);
  background-color: var(--color-product-deep-thought--hover);
}

.ax-badge--deep-thought.ax-badge--clickable:active .ax-badge__background {
  background-color: rgb(167, 162, 179);
  background-color: var(--color-product-deep-thought--active);
}

.ax-badge--luna-dust.ax-badge--clickable:hover .ax-badge__background {
  background-color: rgb(138, 158, 168);
  background-color: var(--color-product-luna-dust--hover);
}

.ax-badge--luna-dust.ax-badge--clickable:active .ax-badge__background {
  background-color: rgb(103, 129, 143);
  background-color: var(--color-product-luna-dust--active);
}
