.ax-tabset__list {
  display: flex;
  align-items: flex-end;
  padding: 0;
  white-space: nowrap;
  overflow: auto
}

.ax-tabset__list::after {
  content: '';
  flex: 1 1 auto;
  border-bottom: 0.0625rem solid rgba(63, 63, 63, .2);
  border-bottom: var(--component-border-width) solid var(--color-theme-border);
}

.ax-tabset__list-item {
  position: relative;
  margin: 0 0.4375rem 0 0;
  margin: 0 var(--component-padding-vertical-medium) 0 0;
  border: 0.0625rem solid transparent;
  border: var(--component-border-width) solid transparent;
  border-radius: 0.1875rem 0.1875rem 0 0;
  border-radius: var(--component-border-radius) var(--component-border-radius) 0 0;
  background-color: rgba(63, 63, 63, .15);
  background-color: var(--color-theme-background--subtle);
  list-style: none;
  transition-property: background-color, border-color, color;
  transition-duration: 200ms;
  transition-duration: var(--transition-time-base);
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition-timing-function: var(--transition-function)
}

.ax-tabset__list-item::after {
  content: '';
  position: absolute;
  right: -0.0625rem;
  right: calc(var(--component-border-width) * -1);
  bottom: -0.0625rem;
  bottom: calc(var(--component-border-width) * -1);
  width: 0.4375rem;
  width: var(--component-padding-vertical-medium);
  transform: translateX(100%);
  border-bottom: 0.0625rem solid rgba(63, 63, 63, .2);
  border-bottom: var(--component-border-width) solid var(--color-theme-border);
}

.ax-tabset__list-item--active {
  padding-bottom: 0.0625rem;
  padding-bottom: var(--component-border-width);
  border-color: rgba(63, 63, 63, .2);
  border-color: var(--color-theme-border);
  border-bottom-color: transparent;
  background-color: rgb(255, 255, 255);
  background-color: var(--color-theme-background);
  color: rgb(22, 145, 207);
  color: var(--color-ui-accent--active);
}

.ax-tabset__list-item--disabled {
  border-bottom-color: rgba(63, 63, 63, .2);
  border-bottom-color: var(--color-theme-border);
  background-color: rgba(63, 63, 63, .05);
  background-color: var(--color-theme-background--disabled);
}

.ax-tabset__button {
  outline: 0;
  border: 0;
  background: none;
  color: inherit;
  text-transform: uppercase;
  line-height: 1rem;
  line-height: var(--component-line-height);
  transition-property: color;
  transition-duration: 200ms;
  transition-duration: var(--transition-time-base);
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition-timing-function: var(--transition-function)
}

.ax-tabset__button:hover {
  color: rgb(35, 158, 219);
  color: var(--color-ui-accent);
  cursor: pointer;
}

.ax-tabset__button:disabled {
  color: rgba(63, 63, 63, .3);
  color: var(--color-theme-text--disabled);
  cursor: default;
}

.ax-tabset__list--medium .ax-tabset__button {
  padding:
    0.375rem
    0.75rem
    0.4375rem;
  padding:
    calc(var(--component-padding-vertical-medium) - var(--component-border-width))
    var(--component-padding-horizontal-medium)
    var(--component-padding-vertical-medium);
}

.ax-tabset__list--large .ax-tabset__button {
  padding:
    0.625rem
    1rem
    0.6875rem;
  padding:
    calc(var(--component-padding-vertical-large) - var(--component-border-width))
    var(--component-padding-horizontal-large)
    var(--component-padding-vertical-large);
}
