.ax-separator {
  width: 100%;
  border-color: rgba(63, 63, 63, .2);
  border-color: var(--color-theme-border);
}

.ax-separator--solid {
  border-style: solid;
}

.ax-separator--dotted {
  border-style: dotted;
}

.ax-separator--horizontal {
  border-width: 0.0625rem 0 0 0;
  border-width: var(--component-border-width-small) 0 0 0;
}

.ax-separator--vertical {
  width: 0;
  height: 100%;
  border-width: 0 0.0625rem 0 0;
  border-width: 0 var(--component-border-width-small) 0 0;
}
