:root {
  /**
   * The designs of the tip is 1.5rem from corner to corner. To calculate
   * the pre-rotated square we use `Math.sqrt((1.5rem**2)/2)`
   */
  --cmp-context-tip-space: 0.5rem;
  --cmp-context-tip-space: calc(var(--spacing-grid-size) * 2);
  --cmp-context-padding-vertical-tiny: 0.125rem;
  --cmp-context-padding-vertical-tiny: calc(var(--spacing-grid-size) / 2);
  --cmp-context-padding-horizontal-tiny: 0.75rem;
  --cmp-context-padding-horizontal-tiny: calc(var(--spacing-grid-size) * 3);
  --cmp-context-padding-vertical-small: 0.375rem;
  --cmp-context-padding-vertical-small: calc(var(--spacing-grid-size) * 1.5);
  --cmp-context-padding-horizontal-small: 0.75rem;
  --cmp-context-padding-horizontal-small: calc(var(--spacing-grid-size) * 3);
  --cmp-context-padding-medium: 1rem;
  --cmp-context-padding-medium: calc(var(--spacing-grid-size) * 4);
  --cmp-context-padding-large: 1.5rem;
  --cmp-context-padding-large: calc(var(--spacing-grid-size) * 6);
  --cmp-context-menu-item-padding-vertical: 0.75rem;
  --cmp-context-menu-item-padding-vertical: calc(var(--spacing-grid-size) * 3);
  --cmp-context-menu-item-padding-vertical-small: 0.375rem;
  --cmp-context-menu-item-padding-vertical-small: calc(var(--spacing-grid-size) * 1.5);
}

.ax-context {
  position: relative;
  border-radius: 0.1875rem;
  border-radius: var(--component-border-radius);
  box-shadow: 0 0 0 0.0625rem rgba(63, 63, 63, .2), 0 0.25rem 0.5625rem 0 rgba(63, 63, 63, .14),
    0 0.0625rem 0.125rem 0.03125rem rgba(63, 63, 63, .15);
  box-shadow: var(--drop-shadow-theme-border), var(--drop-shadow-theme-elevation--x2);
}

.ax-context__content {
  position: relative;
  border-radius: 0.1875rem;
  border-radius: var(--component-border-radius);
  background-color: rgb(255, 255, 255);
  background-color: var(--color-theme-background);
  color: rgb(63, 63, 63);
  color: var(--color-theme-text);
  overflow-y: auto;
  scroll-behavior: smooth;
}

.ax-context-content {
  position: relative;
  overflow: hidden
}

.ax-context--success .ax-context-content {
  background-color: rgb(0, 190, 150);
  background-color: var(--color-ui-success);
  color: rgb(248, 248, 248);
  color: var(--color-ui-white-noise);
}

.ax-context--warning .ax-context-content {
  background-color: rgb(218, 174, 69);
  background-color: var(--color-ui-warning);
  color: rgb(63, 63, 63);
  color: var(--color-ui-carbon);
}

.ax-context--error .ax-context-content {
  background-color: rgb(252, 72, 112);
  background-color: var(--color-ui-error);
  color: rgb(248, 248, 248);
  color: var(--color-ui-white-noise);
}

.ax-context--info .ax-context-content {
  background-color: rgb(35, 158, 219);
  background-color: var(--color-ui-info);
  color: rgb(248, 248, 248);
  color: var(--color-ui-white-noise);
}

.ax-context--carbon .ax-context-content {
  background-color: rgb(63, 63, 63);
  background-color: var(--color-ui-carbon);
  color: rgb(248, 248, 248);
  color: var(--color-ui-white-noise);
}

.ax-context--white .ax-context-content {
  background-color: rgb(255, 255, 255);
  background-color: var(--color-ui-white);
  color: rgb(63, 63, 63);
  color: var(--color-ui-carbon);
}

.ax-context-content--padding-horizontal-tiny {
  padding-right: 0.75rem;
  padding-right: var(--cmp-context-padding-horizontal-tiny);
  padding-left: 0.75rem;
  padding-left: var(--cmp-context-padding-horizontal-tiny);
}

.ax-context-content--padding-horizontal-small {
  padding-right: 0.75rem;
  padding-right: var(--cmp-context-padding-horizontal-small);
  padding-left: 0.75rem;
  padding-left: var(--cmp-context-padding-horizontal-small);
}

.ax-context-content--padding-horizontal-medium {
  padding-right: 1rem;
  padding-right: var(--cmp-context-padding-medium);
  padding-left: 1rem;
  padding-left: var(--cmp-context-padding-medium);
}

.ax-context-content--padding-horizontal-large {
  padding-right: 1.5rem;
  padding-right: var(--cmp-context-padding-large);
  padding-left: 1.5rem;
  padding-left: var(--cmp-context-padding-large);
}

.ax-context-content--padding-vertical-tiny {
  padding-top: 0.125rem;
  padding-top: var(--cmp-context-padding-vertical-tiny);
  padding-bottom: 0.125rem;
  padding-bottom: var(--cmp-context-padding-vertical-tiny);
}

.ax-context-content--padding-vertical-small {
  padding-top: 0.375rem;
  padding-top: var(--cmp-context-padding-vertical-small);
  padding-bottom: 0.375rem;
  padding-bottom: var(--cmp-context-padding-vertical-small);
}

.ax-context-content--padding-vertical-medium {
  padding-top: 1rem;
  padding-top: var(--cmp-context-padding-medium);
  padding-bottom: 1rem;
  padding-bottom: var(--cmp-context-padding-medium);
}

.ax-context-content--padding-vertical-large {
  padding-top: 1.5rem;
  padding-top: var(--cmp-context-padding-large);
  padding-bottom: 1.5rem;
  padding-bottom: var(--cmp-context-padding-large);
}

.ax-context-content + .ax-context-content::before {
  content: '';
  position: absolute;
  top: 0;
  border-top: 0.0625rem solid rgba(63, 63, 63, .2);
  border-top: var(--component-border-width) solid var(--color-theme-border);
}

.ax-context-content--padding-horizontal-medium::before {
  right: 1rem;
  right: var(--cmp-context-padding-medium);
  left: 1rem;
  left: var(--cmp-context-padding-medium);
}

.ax-context-content--padding-horizontal-none::before,
.ax-context-content--padding-horizontal-large::before {
  right: 1.5rem;
  right: var(--cmp-context-padding-large);
  left: 1.5rem;
  left: var(--cmp-context-padding-large);
}

.ax-context-content--full-separator::before {
  right: 0;
  left: 0;
}

.ax-context-content--scrollable .ax-context-content__scroll {
  overflow-y: auto;
}

.ax-context-menu {
  height: 100%;
}

.ax-context-menu--padding-vertical-none {
  padding-top: 0;
  padding-bottom: 0;
}

.ax-context-menu--padding-vertical-medium {
  padding-top: 0.5rem;
  padding-top: var(--cmp-context-tip-space);
  padding-bottom: 0.5rem;
  padding-bottom: var(--cmp-context-tip-space);
}

.ax-context-menu__item {
  display: flex;
  align-items: center;
  width: 100%;
  outline: 0;
  border: 0;
  background-color: rgb(255, 255, 255);
  background-color: var(--color-theme-background);
  color: rgb(63, 63, 63);
  color: var(--color-theme-text);
  text-align: left;
  transition-property: background-color, color;
  transition-duration: 200ms;
  transition-duration: var(--transition-time-base);
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition-timing-function: var(--transition-function);
}

.ax-context-menu__item--padding-vertical-medium {
  padding: 0.75rem 1.5rem;
  padding: var(--cmp-context-menu-item-padding-vertical) var(--cmp-context-padding-large);
}

.ax-context-menu__item--padding-vertical-small {
  padding: 0.375rem 1.5rem;
  padding: var(--cmp-context-menu-item-padding-vertical-small) var(--cmp-context-padding-large);
}

.ax-context-menu__item:focus {
  outline: 0;
  background-color: rgba(63, 63, 63, .08);
  background-color: var(--color-theme-background--active);
  cursor: pointer;
}

.ax-context-menu__item:disabled {
  background-color: transparent;
  color: rgba(63, 63, 63, .3);
  color: var(--color-theme-text--disabled);
  cursor: default;
}

.ax-context-menu__item-content {
  flex: 1 1 auto;
  overflow: hidden;
}

.ax-context-menu__item-checkbox {
  flex: 0 0 auto;
  margin-right: 0.5rem;
  margin-right: var(--spacing-grid-size--x2);
}

.ax-context-menu__item-icon {
  flex: 0 0 auto;
  margin-left: 0.5rem;
  margin-left: var(--spacing-grid-size--x2);
}

.ax-context-menu .ax-context-menu{
  padding: 0
}

.ax-context-menu .ax-context-menu .ax-context-menu__item {
  padding-left: 3rem;
  padding-left: calc(var(--spacing-grid-size) * 12);
}
