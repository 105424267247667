/* stylelint-disable value-keyword-case */
:root {
  --font-family-body: 'Roboto', Helvetica, Arial, sans-serif;

  --font-weight-thin: 100;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;

  --font-size-label: 0.625rem;
  --font-size-small: 0.75rem;
  --font-size-body: 0.8125rem;
  --font-size-large: 0.875rem;
  --font-size-headtitle: 1.0625rem;
  --font-size-headline: 1.375rem;
  --font-size-display1: 2rem;
  --font-size-display2: 2.5rem;

  --line-height-label: 1rem;
  --line-height-body: 1.25rem;
  --line-height-small: var(--line-height-body);
  --line-height-large: var(--line-height-body);
  --line-height-headtitle: 1.5rem;
  --line-height-headline: 1.75rem;
  --line-height-display1: 2.5rem;
  --line-height-display2: 3rem;

  --letter-spacing-tight: -0.00125rem;
  --letter-spacing-loose: 0.001875rem;
}
/* stylelint-enable */
