.ax-modal__container {
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 5;
  z-index: var(--z-index-modal);
}

.ax-modal__container--overlay-shade-1 {
  background-color: rgba(255, 255, 255, .9);
  background-color: rgba(var(--rgb-theme-background--shade-1), var(--opacity-background--overlay-modal));
}

.ax-modal__container--overlay-shade-2 {
  background-color: rgba(248, 248, 248, .9);
  background-color: rgba(var(--rgb-theme-background--shade-2), var(--opacity-background--overlay-modal));
}

.ax-modal__container--overlay-shade-3 {
  background-color: rgba(238, 238, 238, .9);
  background-color: rgba(var(--rgb-theme-background--shade-3), var(--opacity-background--overlay-modal));
}

.ax-modal__container--overlay-shade-4 {
  background-color: rgba(228, 228, 228, .9);
  background-color: rgba(var(--rgb-theme-background--shade-4), var(--opacity-background--overlay-modal));
}

.ax-modal {
  display: flex;
  position: relative;
  flex-direction: column;
  max-width: 100%;
  min-height: 0;
}

.ax-modal__container--padding-x6 .ax-modal { padding: 1.5rem; padding: calc(var(--spacing-grid-size) * 6); }
.ax-modal__container--padding-x8 .ax-modal { padding: 2rem; padding: calc(var(--spacing-grid-size) * 8); }
.ax-modal__container--padding-x12 .ax-modal { padding: 3rem; padding: calc(var(--spacing-grid-size) * 12); }
.ax-modal__container--padding-x16 .ax-modal { padding: 4rem; padding: calc(var(--spacing-grid-size) * 16); }

.ax-modal__body--open {
  overflow: hidden;
}

.ax-modal__mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
