.ax-date-range-selection {
  padding: 0 0.5rem;
  padding: 0 var(--spacing-grid-size--x2);
  border-radius: 1.5rem;
  border-radius: var(--component-round-size-small);
  background-color: rgba(63, 63, 63, .15);
  background-color: var(--color-theme-background--subtle);
}

.ax-date-range-selection__item {
  position: relative;
  height: 1.5rem;
  height: var(--component-round-size-small);
  padding: 0 0.5rem;
  padding: 0 var(--spacing-grid-size--x2);
  outline: 0;
  border: 0;
  background-color: transparent;
  color: rgba(63, 63, 63, .75);
  color: var(--color-theme-text--subtle);
  font-size: 0.75rem;
  font-size: var(--font-size-small);
  font-weight: 500;
  font-weight: var(--font-weight-medium);
  letter-spacing: 0.001875rem;
  letter-spacing: var(--letter-spacing-loose);
  text-transform: uppercase;
  cursor: pointer
}

.ax-date-range-selection__item:nth-last-child(n+2)::after {
  content: '';
  position: absolute;
  top: 50%;
  width: 0.1875rem;
  height: 0.1875rem;
  margin-left: 0.5rem;
  margin-left: var(--spacing-grid-size--x2);
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background-color: rgb(187, 185, 192);
  background-color: var(--color-product-deep-thought);
}

.ax-date-range-selection__item:hover {
  color: rgb(35, 158, 219);
  color: var(--color-ui-accent);
}

.ax-date-range-selection__item:active {
  color: rgb(22, 145, 207);
  color: var(--color-ui-accent--active);
}

.ax-date-range-selection__item--active {
  color: rgb(35, 158, 219);
  color: var(--color-ui-accent);
  cursor: auto;
  cursor: initial;
}
