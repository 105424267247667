/* stylelint-disable no-descending-specificity */
.ax-card {
  color: rgb(63, 63, 63);
  color: var(--color-theme-text);
  overflow: hidden;
  transition-property: background-color, box-shadow;
  transition-duration: 200ms;
  transition-duration: var(--transition-time-base);
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition-timing-function: var(--transition-function)
}
.ax-card:focus {
  outline: none;
}
.ax-card-list--divided .ax-card {
  border-bottom: 0.0625rem dotted rgba(63, 63, 63, .2);
  border-bottom: 0.0625rem dotted var(--color-theme-border);
  border-radius: 0;
}

.ax-card--border {
  box-shadow: 0 0 0 0.0625rem rgba(63, 63, 63, .2);
  box-shadow: var(--drop-shadow-theme-border);
}

.ax-card--border-radius-small {
  border-radius: 0.1875rem;
  border-radius: var(--component-border-radius);
}

.ax-card--border-radius-large {
  border-radius: 0.5rem;
  border-radius: var(--component-border-radius-large);
}

.ax-card--hover.ax-card--shadow,
.ax-card--clickable:focus.ax-card--shadow,
.ax-card--clickable:hover.ax-card--shadow {
  box-shadow: 0 0.25rem 0.5625rem 0 rgba(63, 63, 63, .14),
    0 0.0625rem 0.125rem 0.03125rem rgba(63, 63, 63, .15);
  box-shadow: var(--drop-shadow-theme-elevation--x2);
}

.ax-card--hover.ax-card--border.ax-card--shadow,
.ax-card--clickable:focus.ax-card--border.ax-card--shadow,
.ax-card--clickable:hover.ax-card--border.ax-card--shadow {
  box-shadow:
    0 0 0 0.0625rem rgba(63, 63, 63, .2),
    0 0.25rem 0.5625rem 0 rgba(63, 63, 63, .14),
    0 0.0625rem 0.125rem 0.03125rem rgba(63, 63, 63, .15);
  box-shadow:
    var(--drop-shadow-theme-border),
    var(--drop-shadow-theme-elevation--x2);
}

.ax-card--shadow,
.ax-card--active.ax-card--shadow,
.ax-card--clickable:active.ax-card--shadow {
  box-shadow: 0 0.0625rem 0.1875rem 0 rgba(63, 63, 63, .14),
    0 0.0625rem 0.125rem 0.03125rem rgba(63, 63, 63, .15);
  box-shadow: var(--drop-shadow-theme-elevation--x1);
}

.ax-card--border.ax-card--shadow,
.ax-card--active.ax-card--border.ax-card--shadow,
.ax-card--clickable:active.ax-card--border.ax-card--shadow {
  box-shadow:
    0 0 0 0.0625rem rgba(63, 63, 63, .2),
    0 0.0625rem 0.1875rem 0 rgba(63, 63, 63, .14),
    0 0.0625rem 0.125rem 0.03125rem rgba(63, 63, 63, .15);
  box-shadow:
    var(--drop-shadow-theme-border),
    var(--drop-shadow-theme-elevation--x1);
}

.ax-card--shade-1 { background-color: rgb(255, 255, 255); background-color: var(--color-theme-background--shade-1); }
.ax-card--shade-2 { background-color: rgb(248, 248, 248); background-color: var(--color-theme-background--shade-2); }
.ax-card--shade-3 { background-color: rgb(238, 238, 238); background-color: var(--color-theme-background--shade-3); }
.ax-card--shade-4 { background-color: rgb(228, 228, 228); background-color: var(--color-theme-background--shade-4); }

.ax-card--clickable {
  cursor: pointer;
}

.ax-card__content {
  position: relative;
  transition-property: background-color;
  transition-duration: 200ms;
  transition-duration: var(--transition-time-base);
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition-timing-function: var(--transition-function);
}

.ax-card__content--size-small {
  padding-top: 0.75rem;
  padding-top: var(--spacing-grid-size--x3);
  padding-bottom: 0.75rem;
  padding-bottom: var(--spacing-grid-size--x3);
}

.ax-card__content--size-medium {
  padding-top: 1rem;
  padding-top: var(--spacing-grid-size--x4);
  padding-bottom: 1rem;
  padding-bottom: var(--spacing-grid-size--x4);
}

.ax-card__content--size-large {
  padding-top: 1.5rem;
  padding-top: var(--spacing-grid-size--x6);
  padding-bottom: 1.5rem;
  padding-bottom: var(--spacing-grid-size--x6);
}

.ax-card__content--shade-1 { background-color: rgb(255, 255, 255); background-color: var(--color-theme-background--shade-1); }
.ax-card__content--shade-2 { background-color: rgb(248, 248, 248); background-color: var(--color-theme-background--shade-2); }
.ax-card__content--shade-3 { background-color: rgb(238, 238, 238); background-color: var(--color-theme-background--shade-3); }
.ax-card__content--shade-4 { background-color: rgb(228, 228, 228); background-color: var(--color-theme-background--shade-4); }

.ax-card--hover:not(.ax-card--shadow) .ax-card__content,
.ax-card--clickable:not(.ax-card--shadow):focus .ax-card__content,
.ax-card--clickable:not(.ax-card--shadow):hover .ax-card__content {
  background-color: rgba(63, 63, 63, .04);
  background-color: var(--color-theme-background--hover);
}

.ax-card--active:not(.ax-card--shadow) .ax-card__content,
.ax-card--clickable:not(.ax-card--shadow):active .ax-card__content {
  background-color: rgba(63, 63, 63, .08);
  background-color: var(--color-theme-background--active);
}

.ax-card__content:not(:last-child)::after {
  content: '';
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  border-bottom-width: 0.0625rem;
  border-bottom-color: rgba(63, 63, 63, .2);
  border-bottom-color: var(--color-theme-border);
}

.ax-card__content--separator-dotted::after { border-bottom-style: dotted; }
.ax-card__content--separator-solid::after { border-bottom-style: solid; }

.ax-card__image,
.ax-card__images {
  position: relative;
  border-bottom: 0.0625rem dotted rgba(63, 63, 63, .2);
  border-bottom: 0.0625rem dotted var(--color-theme-border);
}

.ax-card__images--ratio {
  height: 0;
}

.ax-card__images-grid {
  display: -ms-grid;
  display: grid;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  grid-gap: 0.125rem;
}

.ax-card__images-grid-item {
  background-position: center;
  background-size: cover;
}

.ax-card__images--2 .ax-card__images-grid-item:last-child {
  -ms-grid-column: 2;
      grid-column-start: 2;
}

.ax-card__images--3 .ax-card__images-grid-item:nth-child(1) {
  -ms-grid-row-span: 2;
      grid-row-end: span 2;
}

.ax-card__images--3 .ax-card__images-grid-item:nth-child(2) {
  -ms-grid-column: 2;
      grid-column-start: 2;
}

.ax-card__images--3 .ax-card__images-grid-item:nth-child(3) {
  -ms-grid-column: 2;
      grid-column-start: 2;
  -ms-grid-row: 2;
      grid-row-start: 2;
}

.ax-card__images--4 .ax-card__images-grid-item:nth-child(2) {
  -ms-grid-column: 2;
      grid-column-start: 2;
}

.ax-card__images--4 .ax-card__images-grid-item:nth-child(3) {
  -ms-grid-row: 2;
      grid-row-start: 2;
}

.ax-card__images--4 .ax-card__images-grid-item:nth-child(4) {
  -ms-grid-column: 2;
      grid-column-start: 2;
  -ms-grid-row: 2;
      grid-row-start: 2;
}

.ax-card__caption {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding-top: 0.5rem;
  padding-top: var(--spacing-grid-size--x2);
  padding-bottom: 0.5rem;
  padding-bottom: var(--spacing-grid-size--x2);
  background-color: rgba(63, 63, 63, .75);
  background-color: var(--color-theme-day-background--overlay-heavy);
  color: rgb(248, 248, 248);
  color: var(--color-theme-night-text);
}

.ax-card--size-small .ax-card__caption,
  .ax-card--size-small .ax-card__content {
  padding-right: 0.75rem;
  padding-right: var(--spacing-grid-size--x3);
  padding-left: 0.75rem;
  padding-left: var(--spacing-grid-size--x3);
}

.ax-card--size-small .ax-card__content--separator-indented::after {
  right: 0.75rem;
  right: var(--spacing-grid-size--x3);
  left: 0.75rem;
  left: var(--spacing-grid-size--x3);
}

.ax-card--size-medium .ax-card__caption,
  .ax-card--size-medium .ax-card__content {
  padding-right: 1rem;
  padding-right: var(--spacing-grid-size--x4);
  padding-left: 1rem;
  padding-left: var(--spacing-grid-size--x4);
}

.ax-card--size-medium .ax-card__content--separator-indented::after {
  right: 1rem;
  right: var(--spacing-grid-size--x4);
  left: 1rem;
  left: var(--spacing-grid-size--x4);
}

.ax-card--size-large .ax-card__caption,
  .ax-card--size-large .ax-card__content {
  padding-right: 1.5rem;
  padding-right: var(--spacing-grid-size--x6);
  padding-left: 1.5rem;
  padding-left: var(--spacing-grid-size--x6);
}

.ax-card--size-large .ax-card__content--separator-indented::after {
  right: 1.5rem;
  right: var(--spacing-grid-size--x6);
  left: 1.5rem;
  left: var(--spacing-grid-size--x6);
}
