:root {
  --cmp-color-picker-border-width: 0.1875rem;
}

.ax-color-picker__option {
  border-width: 0.1875rem;
  border-width: var(--cmp-color-picker-border-width);
  border-radius: 50%;
  transition-property: background-color, border-color;
  transition-duration: 200ms;
  transition-duration: var(--transition-time-base);
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition-timing-function: var(--transition-function);
}

.ax-color-picker__option--forbidden-planet { background-color: rgb(245, 177, 164); background-color: var(--color-product-forbidden-planet); }
.ax-color-picker__option--tiny-clanger { background-color: rgb(245, 152, 183); background-color: var(--color-product-tiny-clanger); }
.ax-color-picker__option--critical-mass { background-color: rgb(227, 90, 122); background-color: var(--color-product-critical-mass); }
.ax-color-picker__option--fantastic-voyage { background-color: rgb(167, 46, 94); background-color: var(--color-product-fantastic-voyage); }
.ax-color-picker__option--paradise-lost { background-color: rgb(144, 107, 167); background-color: var(--color-product-paradise-lost); }
.ax-color-picker__option--serene-sea { background-color: rgb(144, 160, 214); background-color: var(--color-product-serene-sea); }
.ax-color-picker__option--event-horizon { background-color: rgb(96, 99, 161); background-color: var(--color-product-event-horizon); }
.ax-color-picker__option--electric-dreams { background-color: rgb(59, 121, 204); background-color: var(--color-product-electric-dreams); }
.ax-color-picker__option--outer-limits { background-color: rgb(127, 214, 248); background-color: var(--color-product-outer-limits); }
.ax-color-picker__option--giant-leap { background-color: rgb(85, 205, 230); background-color: var(--color-product-giant-leap); }
.ax-color-picker__option--moon-lagoon { background-color: rgb(36, 173, 189); background-color: var(--color-product-moon-lagoon); }
.ax-color-picker__option--space-invader { background-color: rgb(47, 145, 112); background-color: var(--color-product-space-invader); }
.ax-color-picker__option--extraterrestrial { background-color: rgb(96, 180, 90); background-color: var(--color-product-extraterrestrial); }
.ax-color-picker__option--terra-form { background-color: rgb(138, 197, 57); background-color: var(--color-product-terra-form); }
.ax-color-picker__option--primeval-soup { background-color: rgb(200, 200, 25); background-color: var(--color-product-primeval-soup); }
.ax-color-picker__option--future-shock { background-color: rgb(240, 236, 29); background-color: var(--color-product-future-shock); }
.ax-color-picker__option--sun-maker { background-color: rgb(252, 213, 18); background-color: var(--color-product-sun-maker); }
.ax-color-picker__option--new-horizon { background-color: rgb(255, 190, 10); background-color: var(--color-product-new-horizon); }
.ax-color-picker__option--blast-off { background-color: rgb(249, 145, 50); background-color: var(--color-product-blast-off); }
.ax-color-picker__option--crash-course { background-color: rgb(255, 109, 86); background-color: var(--color-product-crash-course); }
.ax-color-picker__option--solar-rust { background-color: rgb(205, 129, 26); background-color: var(--color-product-solar-rust); }
.ax-color-picker__option--ground-control { background-color: rgb(158, 97, 57); background-color: var(--color-product-ground-control); }
.ax-color-picker__option--space-oddity { background-color: rgb(189, 163, 91); background-color: var(--color-product-space-oddity); }
.ax-color-picker__option--rocky-planet { background-color: rgb(158, 142, 117); background-color: var(--color-product-rocky-planet); }
.ax-color-picker__option--deep-thought { background-color: rgb(187, 185, 192); background-color: var(--color-product-deep-thought); }
.ax-color-picker__option--luna-dust { background-color: rgb(120, 144, 156); background-color: var(--color-product-luna-dust); }

.ax-color-picker__option--empty {
  border: solid rgba(63, 63, 63, .15);
  border: solid var(--color-theme-background--subtle);
}

.ax-color-picker__option--disabled {
  border: solid 0.0625rem rgba(63, 63, 63, .15);
  border: solid var(--component-border-width-small) var(--color-theme-background--subtle);
  background-color: transparent;
}

.ax-color-picker__option--clickable {
  cursor: pointer
}

.ax-color-picker__option--clickable.ax-color-picker__option--forbidden-planet:hover  {
  background-color: rgb(255, 195, 184);
  background-color: var(--color-product-forbidden-planet--hover);
}

.ax-color-picker__option--clickable.ax-color-picker__option--forbidden-planet:active {
  background-color: rgb(232, 159, 144);
  background-color: var(--color-product-forbidden-planet--active);
}

.ax-color-picker__option--clickable.ax-color-picker__option--tiny-clanger:hover  {
  background-color: rgb(255, 171, 200);
  background-color: var(--color-product-tiny-clanger--hover);
}

.ax-color-picker__option--clickable.ax-color-picker__option--tiny-clanger:active {
  background-color: rgb(232, 132, 166);
  background-color: var(--color-product-tiny-clanger--active);
}

.ax-color-picker__option--clickable.ax-color-picker__option--critical-mass:hover  {
  background-color: rgb(240, 108, 139);
  background-color: var(--color-product-critical-mass--hover);
}

.ax-color-picker__option--clickable.ax-color-picker__option--critical-mass:active {
  background-color: rgb(214, 75, 107);
  background-color: var(--color-product-critical-mass--active);
}

.ax-color-picker__option--clickable.ax-color-picker__option--fantastic-voyage:hover  {
  background-color: rgb(181, 60, 106);
  background-color: var(--color-product-fantastic-voyage--hover);
}

.ax-color-picker__option--clickable.ax-color-picker__option--fantastic-voyage:active {
  background-color: rgb(156, 36, 82);
  background-color: var(--color-product-fantastic-voyage--active);
}

.ax-color-picker__option--clickable.ax-color-picker__option--paradise-lost:hover  {
  background-color: rgb(157, 123, 179);
  background-color: var(--color-product-paradise-lost--hover);
}

.ax-color-picker__option--clickable.ax-color-picker__option--paradise-lost:active {
  background-color: rgb(129, 90, 153);
  background-color: var(--color-product-paradise-lost--active);
}

.ax-color-picker__option--clickable.ax-color-picker__option--serene-sea:hover  {
  background-color: rgb(163, 178, 227);
  background-color: var(--color-product-serene-sea--hover);
}

.ax-color-picker__option--clickable.ax-color-picker__option--serene-sea:active {
  background-color: rgb(125, 143, 201);
  background-color: var(--color-product-serene-sea--active);
}

.ax-color-picker__option--clickable.ax-color-picker__option--event-horizon:hover  {
  background-color: rgb(113, 116, 173);
  background-color: var(--color-product-event-horizon--hover);
}

.ax-color-picker__option--clickable.ax-color-picker__option--event-horizon:active {
  background-color: rgb(81, 85, 148);
  background-color: var(--color-product-event-horizon--active);
}

.ax-color-picker__option--clickable.ax-color-picker__option--electric-dreams:hover  {
  background-color: rgb(74, 136, 217);
  background-color: var(--color-product-electric-dreams--hover);
}

.ax-color-picker__option--clickable.ax-color-picker__option--electric-dreams:active {
  background-color: rgb(46, 109, 191);
  background-color: var(--color-product-electric-dreams--active);
}

.ax-color-picker__option--clickable.ax-color-picker__option--outer-limits:hover  {
  background-color: rgb(147, 222, 252);
  background-color: var(--color-product-outer-limits--hover);
}

.ax-color-picker__option--clickable.ax-color-picker__option--outer-limits:active {
  background-color: rgb(108, 199, 235);
  background-color: var(--color-product-outer-limits--active);
}

.ax-color-picker__option--clickable.ax-color-picker__option--giant-leap:hover  {
  background-color: rgb(102, 219, 242);
  background-color: var(--color-product-giant-leap--hover);
}

.ax-color-picker__option--clickable.ax-color-picker__option--giant-leap:active {
  background-color: rgb(69, 192, 217);
  background-color: var(--color-product-giant-leap--active);
}

.ax-color-picker__option--clickable.ax-color-picker__option--moon-lagoon:hover  {
  background-color: rgb(48, 186, 201);
  background-color: var(--color-product-moon-lagoon--hover);
}

.ax-color-picker__option--clickable.ax-color-picker__option--moon-lagoon:active {
  background-color: rgb(25, 161, 176);
  background-color: var(--color-product-moon-lagoon--active);
}

.ax-color-picker__option--clickable.ax-color-picker__option--space-invader:hover  {
  background-color: rgb(58, 158, 125);
  background-color: var(--color-product-space-invader--hover);
}

.ax-color-picker__option--clickable.ax-color-picker__option--space-invader:active {
  background-color: rgb(36, 133, 100);
  background-color: var(--color-product-space-invader--active);
}

.ax-color-picker__option--clickable.ax-color-picker__option--extraterrestrial:hover  {
  background-color: rgb(112, 194, 107);
  background-color: var(--color-product-extraterrestrial--hover);
}

.ax-color-picker__option--clickable.ax-color-picker__option--extraterrestrial:active {
  background-color: rgb(82, 168, 76);
  background-color: var(--color-product-extraterrestrial--active);
}

.ax-color-picker__option--clickable.ax-color-picker__option--terra-form:hover  {
  background-color: rgb(151, 209, 71);
  background-color: var(--color-product-terra-form--hover);
}

.ax-color-picker__option--clickable.ax-color-picker__option--terra-form:active {
  background-color: rgb(125, 184, 44);
  background-color: var(--color-product-terra-form--active);
}

.ax-color-picker__option--clickable.ax-color-picker__option--primeval-soup:hover  {
  background-color: rgb(212, 212, 38);
  background-color: var(--color-product-primeval-soup--hover);
}

.ax-color-picker__option--clickable.ax-color-picker__option--primeval-soup:active {
  background-color: rgb(186, 186, 15);
  background-color: var(--color-product-primeval-soup--active);
}

.ax-color-picker__option--clickable.ax-color-picker__option--future-shock:hover  {
  background-color: rgb(252, 249, 43);
  background-color: var(--color-product-future-shock--hover);
}

.ax-color-picker__option--clickable.ax-color-picker__option--future-shock:active {
  background-color: rgb(227, 223, 16);
  background-color: var(--color-product-future-shock--active);
}

.ax-color-picker__option--clickable.ax-color-picker__option--sun-maker:hover  {
  background-color: rgb(255, 220, 46);
  background-color: var(--color-product-sun-maker--hover);
}

.ax-color-picker__option--clickable.ax-color-picker__option--sun-maker:active {
  background-color: rgb(240, 201, 5);
  background-color: var(--color-product-sun-maker--active);
}

.ax-color-picker__option--clickable.ax-color-picker__option--new-horizon:hover  {
  background-color: rgb(254, 199, 49);
  background-color: var(--color-product-new-horizon--hover);
}

.ax-color-picker__option--clickable.ax-color-picker__option--new-horizon:active {
  background-color: rgb(242, 178, 0);
  background-color: var(--color-product-new-horizon--active);
}

.ax-color-picker__option--clickable.ax-color-picker__option--blast-off:hover  {
  background-color: rgb(255, 157, 67);
  background-color: var(--color-product-blast-off--hover);
}

.ax-color-picker__option--clickable.ax-color-picker__option--blast-off:active {
  background-color: rgb(237, 132, 36);
  background-color: var(--color-product-blast-off--active);
}

.ax-color-picker__option--clickable.ax-color-picker__option--crash-course:hover  {
  background-color: rgb(255, 126, 106);
  background-color: var(--color-product-crash-course--hover);
}

.ax-color-picker__option--clickable.ax-color-picker__option--crash-course:active {
  background-color: rgb(242, 93, 70);
  background-color: var(--color-product-crash-course--active);
}

.ax-color-picker__option--clickable.ax-color-picker__option--solar-rust:hover  {
  background-color: rgb(217, 143, 39);
  background-color: var(--color-product-solar-rust--hover);
}

.ax-color-picker__option--clickable.ax-color-picker__option--solar-rust:active {
  background-color: rgb(191, 118, 15);
  background-color: var(--color-product-solar-rust--active);
}

.ax-color-picker__option--clickable.ax-color-picker__option--ground-control:hover  {
  background-color: rgb(171, 110, 70);
  background-color: var(--color-product-ground-control--hover);
}

.ax-color-picker__option--clickable.ax-color-picker__option--ground-control:active {
  background-color: rgb(145, 85, 45);
  background-color: var(--color-product-ground-control--active);
}

.ax-color-picker__option--clickable.ax-color-picker__option--space-oddity:hover  {
  background-color: rgb(201, 176, 107);
  background-color: var(--color-product-space-oddity--hover);
}

.ax-color-picker__option--clickable.ax-color-picker__option--space-oddity:active {
  background-color: rgb(176, 149, 76);
  background-color: var(--color-product-space-oddity--active);
}

.ax-color-picker__option--clickable.ax-color-picker__option--rocky-planet:hover  {
  background-color: rgb(171, 157, 135);
  background-color: var(--color-product-rocky-planet--hover);
}

.ax-color-picker__option--clickable.ax-color-picker__option--rocky-planet:active {
  background-color: rgb(145, 128, 100);
  background-color: var(--color-product-rocky-planet--active);
}

.ax-color-picker__option--clickable.ax-color-picker__option--deep-thought:hover  {
  background-color: rgb(204, 204, 204);
  background-color: var(--color-product-deep-thought--hover);
}

.ax-color-picker__option--clickable.ax-color-picker__option--deep-thought:active {
  background-color: rgb(167, 162, 179);
  background-color: var(--color-product-deep-thought--active);
}

.ax-color-picker__option--clickable.ax-color-picker__option--luna-dust:hover  {
  background-color: rgb(138, 158, 168);
  background-color: var(--color-product-luna-dust--hover);
}

.ax-color-picker__option--clickable.ax-color-picker__option--luna-dust:active {
  background-color: rgb(103, 129, 143);
  background-color: var(--color-product-luna-dust--active);
}

.ax-color-picker__option--clickable.ax-color-picker__option--empty:hover  {
  border-color: rgba(63, 63, 63, .04);
  border-color: var(--color-theme-background--hover);
}

.ax-color-picker__option--clickable.ax-color-picker__option--empty:active {
  border-color: rgba(63, 63, 63, .08);
  border-color: var(--color-theme-background--active);
}
