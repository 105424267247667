.ax-logo-page {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  height: 100%;
  padding: 0 1.5rem;
  padding: 0 var(--page-padding-horizontal);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  color: rgb(63, 63, 63);
  color: var(--color-theme-text);
}

.ax-logo-page--night { background-color: rgb(53, 53, 53); background-color: var(--color-ui-carbon--dark); }
.ax-logo-page--day { background-color: rgb(248, 248, 248); background-color: var(--color-ui-white-noise); }

.ax-logo-page__header {
  flex: 0 0 auto;
  width: 100%;
  max-width: 62rem;
  max-width: var(--layout-width-medium);
  margin: 0 auto;
}

.ax-logo-page__body {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  justify-content: center;
  padding: 1.5rem 0;
  padding: var(--page-padding-vertical) 0;
}
