.ax-text--align-left   { text-align: left; }
.ax-text--align-center { text-align: center; }
.ax-text--align-right  { text-align: right; }

@media (min-width: 48rem) {
  .ax-text--align-left--small   { text-align: left; }
  .ax-text--align-center--small { text-align: center; }
  .ax-text--align-right--small  { text-align: right; }
}

@media (min-width: 62rem) {
  .ax-text--align-left--medium   { text-align: left; }
  .ax-text--align-center--medium { text-align: center; }
  .ax-text--align-right--medium  { text-align: right; }
}

@media (min-width: 75rem) {
  .ax-text--align-left--large   { text-align: left; }
  .ax-text--align-center--large { text-align: center; }
  .ax-text--align-right--large  { text-align: right; }
}

.ax-text--break-none { white-space: nowrap; }
.ax-text--break-all  { white-space: normal; white-space: initial; word-break: break-all; }
.ax-text--break-word { white-space: normal; white-space: initial; word-wrap: break-word; }

.ax-text--case-upper   { text-transform: uppercase; }
.ax-text--case-capital { text-transform: capitalize; }
.ax-text--case-lower   { text-transform: lowercase; }

.ax-text--color-body { color: rgb(63, 63, 63); color: var(--color-theme-text); }
.ax-text--color-day { color: rgb(63, 63, 63); color: var(--color-theme-day-text); }
.ax-text--color-night { color: rgb(248, 248, 248); color: var(--color-theme-night-text); }
.ax-text--color-error { color: rgb(252, 72, 112); color: var(--color-ui-error); }
.ax-text--color-success { color: rgb(0, 190, 150); color: var(--color-ui-success); }
.ax-text--color-warning { color: rgb(218, 174, 69); color: var(--color-ui-warning); }
.ax-text--color-disabled { color: rgba(63, 63, 63, .3); color: var(--color-theme-text--disabled); }
.ax-text--color-subtle { color: rgba(63, 63, 63, .75); color: var(--color-theme-text--subtle); }
.ax-text--color-twitter { color: rgb(29, 161, 242); color: var(--color-social-twitter); }
.ax-text--color-facebook { color: rgb(23, 118, 242); color: var(--color-social-facebook); }
.ax-text--color-instagram { color: rgb(183, 71, 146); color: var(--color-social-instagram); }
.ax-text--color-forbidden-planet { color: rgb(245, 177, 164); color: var(--color-product-forbidden-planet); }
.ax-text--color-tiny-clanger { color: rgb(245, 152, 183); color: var(--color-product-tiny-clanger); }
.ax-text--color-critical-mass { color: rgb(227, 90, 122); color: var(--color-product-critical-mass); }
.ax-text--color-fantastic-voyage { color: rgb(167, 46, 94); color: var(--color-product-fantastic-voyage); }
.ax-text--color-paradise-lost { color: rgb(144, 107, 167); color: var(--color-product-paradise-lost); }
.ax-text--color-serene-sea { color: rgb(144, 160, 214); color: var(--color-product-serene-sea); }
.ax-text--color-event-horizon { color: rgb(96, 99, 161); color: var(--color-product-event-horizon); }
.ax-text--color-electric-dreams { color: rgb(59, 121, 204); color: var(--color-product-electric-dreams); }
.ax-text--color-outer-limits { color: rgb(127, 214, 248); color: var(--color-product-outer-limits); }
.ax-text--color-giant-leap { color: rgb(85, 205, 230); color: var(--color-product-giant-leap); }
.ax-text--color-moon-lagoon { color: rgb(36, 173, 189); color: var(--color-product-moon-lagoon); }
.ax-text--color-space-invader { color: rgb(47, 145, 112); color: var(--color-product-space-invader); }
.ax-text--color-extraterrestrial { color: rgb(96, 180, 90); color: var(--color-product-extraterrestrial); }
.ax-text--color-terra-form { color: rgb(138, 197, 57); color: var(--color-product-terra-form); }
.ax-text--color-primeval-soup { color: rgb(200, 200, 25); color: var(--color-product-primeval-soup); }
.ax-text--color-future-shock { color: rgb(240, 236, 29); color: var(--color-product-future-shock); }
.ax-text--color-sun-maker { color: rgb(252, 213, 18); color: var(--color-product-sun-maker); }
.ax-text--color-new-horizon { color: rgb(255, 190, 10); color: var(--color-product-new-horizon); }
.ax-text--color-blast-off { color: rgb(249, 145, 50); color: var(--color-product-blast-off); }
.ax-text--color-crash-course { color: rgb(255, 109, 86); color: var(--color-product-crash-course); }
.ax-text--color-solar-rust { color: rgb(205, 129, 26); color: var(--color-product-solar-rust); }
.ax-text--color-ground-control { color: rgb(158, 97, 57); color: var(--color-product-ground-control); }
.ax-text--color-space-oddity { color: rgb(189, 163, 91); color: var(--color-product-space-oddity); }
.ax-text--color-rocky-planet { color: rgb(158, 142, 117); color: var(--color-product-rocky-planet); }
.ax-text--color-deep-thought { color: rgb(187, 185, 192); color: var(--color-product-deep-thought); }
.ax-text--color-luna-dust { color: rgb(120, 144, 156); color: var(--color-product-luna-dust); }
.ax-text--color-sentiment-positive { color: rgb(76, 161, 90); color: var(--color-sentiment-positive); }
.ax-text--color-sentiment-negative { color: rgb(215, 74, 74); color: var(--color-sentiment-negative); }
.ax-text--color-sentiment-neutral { color: rgb(187, 185, 192); color: var(--color-sentiment-neutral); }
.ax-text--color-ui-accent { color: rgb(35, 158, 219); color: var(--color-ui-accent); }

.ax-text--ellipsis {
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.ax-text--emphasize { font-style: italic; }

.ax-text--size-label {
  font-size: 0.625rem;
  font-size: var(--font-size-label);
  font-weight: 400;
  font-weight: var(--font-weight-regular);
  letter-spacing: 0.001875rem;
  letter-spacing: var(--letter-spacing-loose);
  text-transform: uppercase;
  line-height: 1rem;
  line-height: var(--line-height-label);
}

.ax-text--size-small {
  font-size: 0.75rem;
  font-size: var(--font-size-small);
}

.ax-text--size-body {
  font-size: 0.8125rem;
  font-size: var(--font-size-body);
  line-height: 1.25rem;
  line-height: var(--line-height-body);
}

.ax-text--size-large {
  font-size: 0.875rem;
  font-size: var(--font-size-large);
  line-height: 1.25rem;
  line-height: var(--line-height-large);
}

.ax-text--size-headtitle {
  font-size: 1.0625rem;
  font-size: var(--font-size-headtitle);
  line-height: 1.5rem;
  line-height: var(--line-height-headtitle);
}

.ax-text--size-headline {
  font-size: 1.375rem;
  font-size: var(--font-size-headline);
  font-weight: 300;
  font-weight: var(--font-weight-light);
  line-height: 1.75rem;
  line-height: var(--line-height-headline);
}

.ax-text--size-display2 {
  font-size: 2.5rem;
  font-size: var(--font-size-display2);
  line-height: 3rem;
  line-height: var(--line-height-display2);
}

.ax-text--size-display1 {
  font-size: 2rem;
  font-size: var(--font-size-display1);
  line-height: 2.5rem;
  line-height: var(--line-height-display1);
}

.ax-text--size-headtitle,
.ax-text--size-headline,
.ax-text--size-display2,
.ax-text--size-display1 {
  letter-spacing: -0.00125rem;
  letter-spacing: var(--letter-spacing-tight);
}

.ax-text--strike         { text-decoration: line-through; }
.ax-text--underline-body { text-decoration: underline; }

.ax-text--underline-display2,
.ax-text--underline-display1,
.ax-text--underline-headline {
  border-bottom: 0.0625rem solid rgba(63, 63, 63, .2);
  border-bottom: var(--component-border-width) solid var(--color-theme-border);
}

.ax-text--underline-headline { padding-bottom: 0.5rem; padding-bottom: calc(var(--spacing-grid-size) * 2); }
.ax-text--underline-display1 { padding-bottom: 0.5rem; padding-bottom: calc(var(--spacing-grid-size) * 2); }
.ax-text--underline-display2 { padding-bottom: 0.75rem; padding-bottom: calc(var(--spacing-grid-size) * 3); }

.ax-text--size-large,
.ax-text--size-headtitle {
  font-weight: 400;
  font-weight: var(--font-weight-regular);
}

.ax-text--strong,
.ax-text--size-label.ax-text--strong,
.ax-text--size-label .ax-text--strong {
  font-weight: 700;
  font-weight: var(--font-weight-bold);
}

.ax-text--size-display2,
.ax-text--size-display1 {
  font-weight: 100;
  font-weight: var(--font-weight-thin);
}

.ax-text--size-headline.ax-text--strong,
.ax-text--size-headline .ax-text--strong,
.ax-text--size-display1.ax-text--strong,
.ax-text--size-display1 .ax-text--strong,
.ax-text--size-display2.ax-text--strong,
.ax-text--size-display2 .ax-text--strong {
  font-weight: 500;
  font-weight: var(--font-weight-medium);
}
