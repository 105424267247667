.ax-progress-button {
  position: relative;
}

.ax-progress-button__indicator {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  transform-origin: center;
  transition-property: transform;
  transition-duration: 200ms;
  transition-duration: var(--transition-time-base);
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition-timing-function: var(--transition-function)
}

.ax-progress-button--in-progress .ax-progress-button__indicator {
  transform: translate(-50%, -50%) scale(1);
}

.ax-progress-button__content {
  display: flex;
}
