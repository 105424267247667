:root {
  --ax-slider-track-size--small: 0.25rem;
  --ax-slider-track-size--small: var(--spacing-grid-size--x1);
  --ax-slider-track-size--medium: 0.5rem;
  --ax-slider-track-size--medium: var(--spacing-grid-size--x2);
  --ax-slider-thumb-size--small: 1rem;
  --ax-slider-thumb-size--small: var(--spacing-grid-size--x4);
  --ax-slider-thumb-size--medium: 1.5rem;
  --ax-slider-thumb-size--medium: var(--spacing-grid-size--x6);
  --ax-slider-thumb-border-width: 0.0625rem;
}

.ax-slider {
  position: relative;
  outline: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.ax-slider__track {
  position: relative;
  top: 50%;
  right: 0;
  left: 0;
  transform: translateY(-50%);
  background-color: rgba(63, 63, 63, .15);
  background-color: var(--color-theme-background--subtle);
  cursor: pointer;
}

.ax-slider__fill {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: rgb(35, 158, 219);
  background-color: var(--color-ui-accent);
}

.ax-slider__marker {
  position: absolute;
  top: -50%;
  opacity: 0.75;
  background-color: rgba(63, 63, 63, .75);
  background-color: var(--color-theme-background--overlay-heavy);
}

.ax-slider__thumb {
  position: absolute;
  top: 0;
  transform: translateX(-50%);
  border: 0.0625rem solid rgba(63, 63, 63, .2);
  border: var(--ax-slider-thumb-border-width) solid var(--color-theme-border);
  border-radius: 50%;
  box-shadow: 0 0.0625rem 0.1875rem 0 rgba(63, 63, 63, .14),
    0 0.0625rem 0.125rem 0.03125rem rgba(63, 63, 63, .15);
  box-shadow: var(--drop-shadow-theme-elevation--x1);
  background-color: rgb(255, 255, 255);
  background-color: var(--color-ui-white);
  cursor: pointer;
}

.ax-slider__range .ax-slider__thumb {
  transform: translateX(-50%) translateY(-30%);
}

.ax-slider--small {
  height: 1.5rem;
  height: var(--spacing-grid-size--x6);
  margin: 0 0.5rem;
  margin: 0 calc(var(--ax-slider-thumb-size--small) / 2)
}

.ax-slider--small .ax-slider__track,
  .ax-slider--small .ax-slider__fill {
  height: 0.25rem;
  height: var(--ax-slider-track-size--small);
  border-radius: 0.25rem;
  border-radius: var(--ax-slider-track-size--small);
}

.ax-slider--small .ax-slider__marker {
  width: 0.125rem;
  height: 0.5rem;
}

.ax-slider--small .ax-slider__thumb {
  width: 1rem;
  width: var(--ax-slider-thumb-size--small);
  min-width: 0.75rem;
  min-width: var(--spacing-grid-size--x3);
  height: 1rem;
  height: var(--ax-slider-thumb-size--small);
  min-height: 0.75rem;
  min-height: var(--spacing-grid-size--x3);
}

.ax-slider--medium {
  height: 1.5rem;
  height: var(--ax-slider-thumb-size--medium);
  margin: 0 0.75rem;
  margin: 0 calc(var(--ax-slider-thumb-size--medium) / 2)
}

.ax-slider--medium .ax-slider__track,
  .ax-slider--medium .ax-slider__fill {
  height: 0.5rem;
  height: var(--ax-slider-track-size--medium);
  border-radius: 0.5rem;
  border-radius: var(--ax-slider-track-size--medium);
}

.ax-slider--medium .ax-slider__marker {
  width: 0.25rem;
  height: 1rem;
}

.ax-slider--medium .ax-slider__thumb {
  width: 1.5rem;
  width: var(--ax-slider-thumb-size--medium);
  min-width: 1rem;
  min-width: var(--ax-slider-thumb-size--small);
  height: 1.5rem;
  height: var(--ax-slider-thumb-size--medium);
  min-height: 1rem;
  min-height: var(--ax-slider-thumb-size--small);
}

.ax-slider--disabled .ax-slider__thumb {
  cursor: default;
}

.ax-slider--disabled .ax-slider__fill {
  background-color: rgba(63, 63, 63, .2);
  background-color: var(--color-theme-border);
}

