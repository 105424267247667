.ax-editable-title {
  display: inline-flex;
  align-items: center;
  max-width: 100%;
  overflow: hidden;
}

.ax-editable-title__input {
  flex: 0 1 100%;
  min-width: 0;
}

.ax-editable-title__icon {
  flex: 0 0 auto;
  margin-left: 0.25rem;
  margin-left: var(--spacing-grid-size);
}
