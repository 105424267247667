.ax-position {
  margin: 0.125rem;
  margin: calc(var(--spacing-grid-size--x1) * 0.5);
  z-index: 5;
  z-index: var(--z-index-position);
}

.ax-position--arrow[x-placement*='bottom'] {
  margin-top: 0.875rem;
  margin-top: calc(var(--cmp-context-tip-edge-to-edge) * 0.5 + var(--spacing-grid-size--x2));
}

.ax-position--arrow[x-placement*='top'] {
  margin-bottom: 0.875rem;
  margin-bottom: calc(var(--cmp-context-tip-edge-to-edge) * 0.5 + var(--spacing-grid-size--x2));
}

.ax-position--arrow[x-placement*='left'] {
  margin-right: 0.875rem;
  margin-right: calc(var(--cmp-context-tip-edge-to-edge) * 0.5 + var(--spacing-grid-size--x2));
}

.ax-position--arrow[x-placement*='right'] {
  margin-left: 0.875rem;
  margin-left: calc(var(--cmp-context-tip-edge-to-edge) * 0.5 + var(--spacing-grid-size--x2));
}

.ax-position__mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5;
  z-index: var(--z-index-position);
}
