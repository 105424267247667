.ax-link {
  color: inherit;
  font-style: inherit;
  text-decoration: inherit;
  transition-property: color;
  transition-duration: 200ms;
  transition-duration: var(--transition-time-base);
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition-timing-function: var(--transition-function)
}

.ax-link:hover,
  .ax-link:active {
  color: inherit;
  text-decoration: inherit;
  cursor: pointer;
}

.ax-link:focus {
  outline: none;
}

.ax-link--style-normal {
  color: rgb(35, 158, 219);
  color: var(--color-ui-accent)
}

.ax-link--style-normal:hover,
  .ax-link--style-normal:focus {
  color: rgb(48, 171, 232);
  color: var(--color-ui-accent--hover);
}

.ax-link--style-normal:active {
  color: rgb(22, 145, 207);
  color: var(--color-ui-accent--active);
}

.ax-link--style-body { color: rgb(63, 63, 63); color: var(--color-theme-text); }
.ax-link--style-subtle { color: rgba(63, 63, 63, .75); color: var(--color-theme-text--subtle); }

.ax-link--style-body:hover,
  .ax-link--style-body:focus,
  .ax-link--style-subtle:hover,
  .ax-link--style-subtle:focus {
  color: rgb(48, 171, 232);
  color: var(--color-ui-accent--hover);
}

.ax-link--style-body:active, .ax-link--style-subtle:active {
  color: rgb(22, 145, 207);
  color: var(--color-ui-accent--active);
}

.ax-link--style-day {
  color: rgba(63, 63, 63, .75);
  color: var(--color-theme-day-text--subtle)
}

.ax-link--style-day:hover,
  .ax-link--style-day:focus,
  .ax-link--style-day:active {
  color: rgb(63, 63, 63);
  color: var(--color-theme-day-text);
}

.ax-link--style-night {
  color: rgba(248, 248, 248, .75);
  color: var(--color-theme-night-text--subtle)
}

.ax-link--style-night:hover,
  .ax-link--style-night:focus,
  .ax-link--style-night:active {
  color: rgb(248, 248, 248);
  color: var(--color-theme-night-text);
}

.ax-link--style-mono {
  color: rgba(63, 63, 63, .75);
  color: var(--color-theme-text--subtle)
}

.ax-link--style-mono:hover,
  .ax-link--style-mono:focus,
  .ax-link--style-mono:active {
  color: rgb(63, 63, 63);
  color: var(--color-theme-text);
}
