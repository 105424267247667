.ax-chiplist {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.5rem;
  margin-top: var(--spacing-grid-size--x2);
  margin-bottom: 0.5rem;
  margin-bottom: var(--spacing-grid-size--x2);
  overflow: hidden;
}

.ax-chiplist-label {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.25rem;
  margin-right: var(--spacing-grid-size--x1);
  margin-bottom: var(--cmp-inline-spacing);
  margin-bottom: var(--cmp-inline-spacing);
  font-weight: 700;
  font-weight: var(--font-weight-bold);
  line-height: 1rem;
  line-height: var(--component-line-height);
}

.ax-chiplist--small,
.ax-chiplist--medium,
.ax-chiplist--large {
  transition-property: max-height, opacity, margin;
  transition-duration: 100ms;
  transition-duration: var(--transition-time-fast);
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition-timing-function: var(--transition-function);
}

.ax-chiplist--small {
  max-height: 1.5rem;
  max-height: var(--component-total-line-height-small);
}

.ax-chiplist--medium {
  max-height: 2rem;
  max-height: var(--component-total-line-height-medium);
}

.ax-chiplist--large {
  max-height: 2.625rem;
  max-height: var(--component-total-line-height-large);
}

.ax-chiplist-label--small {
  padding-top: 0.1875rem;
  padding-top: var(--component-padding-vertical-small);
  padding-right: 0.75rem;
  padding-right: var(--component-padding-horizontal-small);
  padding-bottom: 0.1875rem;
  padding-bottom: var(--component-padding-vertical-small);
  padding-left: 0;
  font-size: 0.75rem;
  font-size: var(--font-size-small);
}

.ax-chiplist-label--medium {
  padding-top: 0.4375rem;
  padding-top: var(--component-padding-vertical-medium);
  padding-right: 0.75rem;
  padding-right: var(--component-padding-horizontal-medium);
  padding-bottom: 0.4375rem;
  padding-bottom: var(--component-padding-vertical-medium);
  padding-left: 0;
  font-size: var(--font-size-medium);
  font-size: var(--font-size-medium);
}

.ax-chiplist-label--large {
  padding-top: 0.6875rem;
  padding-top: var(--component-padding-vertical-large);
  padding-right: 1rem;
  padding-right: var(--component-padding-horizontal-large);
  padding-bottom: 0.6875rem;
  padding-bottom: var(--component-padding-vertical-large);
  padding-left: 0;
  font-size: 0.875rem;
  font-size: var(--font-size-large);
}

.ax-chiplist--expanded {
  max-height: 62.5rem;
  max-height: var(--component-max-height);
}

.ax-chiplist--expandable {
  cursor: pointer;
}
