/* Hack: around webkits autofill styling */
@keyframes inputAutofillFix {
  to {
    background-color: transparent;
    color: inherit;
  }
}

@keyframes appear-standard {
  from { transform: scale3d(0.8, 0.8, 1); opacity: 0; }
  to   { transform: scale3d(1, 1, 1);     opacity: 1; }
}

@keyframes disappear-standard {
  from { transform: scale3d(1, 1, 1);     opacity: 1; }
  to   { transform: scale3d(0.8, 0.8, 1); opacity: 0; }
}

@keyframes appear-fade {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@keyframes disappear-fade {
  from { opacity: 1; }
  to   { opacity: 0; }
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to   { transform: rotate(360deg); }
}

/* stylelint-disable declaration-no-important */
@media (max-width: 47.9375rem) {
  .ax-hidden-until--small  {
    display: none !important;
  }
}

@media (max-width: 61.9375rem) {
  .ax-hidden-until--medium  {
    display: none !important;
  }
}

@media (max-width: 74.9375rem) {
  .ax-hidden-until--large  {
    display: none !important;
  }
}

@media (min-width: 48rem) {
  .ax-visible-until--small  {
    display: none !important;
  }
}

@media (min-width: 62rem) {
  .ax-visible-until--medium  {
    display: none !important;
  }
}

@media (min-width: 75rem) {
  .ax-visible-until--large  {
    display: none !important;
  }
}

/* stylelint-enable */
.ax-space--x0 {
  margin-top: 0;
  margin-bottom: 0;
}

.ax-space--x1 {
  margin-top: 0.25rem;
  margin-top: var(--spacing-grid-size--x1);
  margin-bottom: 0.25rem;
  margin-bottom: var(--spacing-grid-size--x1);
}

.ax-space--x2 {
  margin-top: 0.5rem;
  margin-top: var(--spacing-grid-size--x2);
  margin-bottom: 0.5rem;
  margin-bottom: var(--spacing-grid-size--x2);
}

.ax-space--x3 {
  margin-top: 0.75rem;
  margin-top: var(--spacing-grid-size--x3);
  margin-bottom: 0.75rem;
  margin-bottom: var(--spacing-grid-size--x3);
}

.ax-space--x4 {
  margin-top: 1rem;
  margin-top: var(--spacing-grid-size--x4);
  margin-bottom: 1rem;
  margin-bottom: var(--spacing-grid-size--x4);
}

.ax-space--x5 {
  margin-top: 1.25rem;
  margin-top: var(--spacing-grid-size--x5);
  margin-bottom: 1.25rem;
  margin-bottom: var(--spacing-grid-size--x5);
}

.ax-space--x6 {
  margin-top: 1.5rem;
  margin-top: var(--spacing-grid-size--x6);
  margin-bottom: 1.5rem;
  margin-bottom: var(--spacing-grid-size--x6);
}

.ax-space--x8 {
  margin-top: 2rem;
  margin-top: var(--spacing-grid-size--x8);
  margin-bottom: 2rem;
  margin-bottom: var(--spacing-grid-size--x8);
}

.ax-space--x0:first-child, .ax-space--x1:first-child, .ax-space--x2:first-child, .ax-space--x3:first-child, .ax-space--x4:first-child, .ax-space--x5:first-child, .ax-space--x6:first-child, .ax-space--x8:first-child {
  margin-top: 0;
}

.ax-space--x0:last-child,  .ax-space--x1:last-child,  .ax-space--x2:last-child,  .ax-space--x3:last-child,  .ax-space--x4:last-child,  .ax-space--x5:last-child,  .ax-space--x6:last-child,  .ax-space--x8:last-child  {
  margin-bottom: 0;
}

.ax-sticky {
  position: -webkit-sticky;
  position: sticky;
  z-index: 1;
  z-index: var(--z-index-sticky);
}

.ax-container {
  position: relative;
}

.ax-cloak {
  opacity: 0;
  transition-property: opacity;
  transition-duration: 200ms;
  transition-duration: var(--transition-time-base);
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition-timing-function: var(--transition-function);
  pointer-events: none
}

.ax-cloak__container:hover .ax-cloak, .ax-cloak.ax-cloak--visible {
  opacity: 1;
  pointer-events: all;
}

.ax-pointer--disabled {
  pointer-events: none;
}
