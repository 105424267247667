.ax-editable-line {
  position: relative;
  padding: 0 0.25rem 0 0;
  padding: 0 var(--spacing-grid-size) 0 0;
  white-space: pre;
}

.ax-editable-line__structure {
  visibility: hidden;
}

.ax-editable-line__input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-width: 0;
  height: 100%;
  margin: 0;
  padding: 0;
  border: 0.0625rem solid transparent;
  border-radius: 0.1875rem;
  border-radius: var(--component-border-radius);
  background-color: transparent;
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  text-overflow: ellipsis;
  line-height: inherit;
  transition-property: border-color;
  transition-duration: 200ms;
  transition-duration: var(--transition-time-base);
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition-timing-function: var(--transition-function);
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none
}

.ax-editable-line__input:hover {
  border-color: rgba(63, 63, 63, .2);
  border-color: var(--color-theme-border);
}

.ax-editable-line__input:focus {
  outline: none;
  border-color: rgba(35, 158, 219, .5);
  border-color: rgba(var(--rgb-ui-accent), var(--opacity-input-border--focused));
}

.ax-editable-line__input:-ms-input-placeholder {
  color: rgba(63, 63, 63, .3);
  color: var(--color-theme-text--disabled);
  -ms-user-select: none;
      user-select: none;
}

.ax-editable-line__input::-ms-input-placeholder {
  color: rgba(63, 63, 63, .3);
  color: var(--color-theme-text--disabled);
  -ms-user-select: none;
      user-select: none;
}

.ax-editable-line__input::placeholder {
  color: rgba(63, 63, 63, .3);
  color: var(--color-theme-text--disabled);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.ax-editable-line__input:focus:-ms-input-placeholder {
  color: transparent;
}

.ax-editable-line__input:focus::-ms-input-placeholder {
  color: transparent;
}

.ax-editable-line__input:focus::placeholder {
  color: transparent;
}

.ax-editable-line__input[value='']:focus + .ax-editable-line__structure {
  width: 0;
}
