/* stylelint-disable max-line-length */
:root {
  --rgb-theme-night-main: var(--rgb-ui-white-noise);
  --rgb-theme-night-main--dark: var(--rgb-ui-white-noise--dark);
  --rgb-theme-night-main--darker: var(--rgb-ui-white-noise--darker);

  --rgb-theme-night-background--shade-1: var(--rgb-ui-black);
  --rgb-theme-night-background--shade-2: var(--rgb-ui-carbon--darker);
  --rgb-theme-night-background--shade-3: var(--rgb-ui-carbon--dark);
  --rgb-theme-night-background--shade-4: var(--rgb-ui-carbon);

  --color-theme-night-main: rgb(var(--rgb-theme-night-main));
  --color-theme-night-main--dark: rgb(var(--rgb-theme-night-main--dark));
  --color-theme-night-main--darker: rgb(var(--rgb-theme-night-main--darker));

  --color-theme-night-text: var(--color-theme-night-main);
  --color-theme-night-text--subtle: rgba(var(--rgb-theme-night-main), var(--opacity-text--subtle));
  --color-theme-night-text--disabled: rgba(var(--rgb-theme-night-main), var(--opacity-text--disabled));

  --color-theme-night-background--shade-1: rgb(var(--rgb-theme-night-background--shade-1));
  --color-theme-night-background--shade-2: rgb(var(--rgb-theme-night-background--shade-2));
  --color-theme-night-background--shade-3: rgb(var(--rgb-theme-night-background--shade-3));
  --color-theme-night-background--shade-4: rgb(var(--rgb-theme-night-background--shade-4));

  --color-theme-night-background: var(--color-theme-night-background--shade-2);
  --color-theme-night-background--overlay-heavy: rgba(var(--rgb-theme-night-main), var(--opacity-background--overlay-heavy));
  --color-theme-night-background--overlay-light: rgba(var(--rgb-theme-night-main), var(--opacity-background--overlay-light));
  --color-theme-night-background--subtle: rgba(var(--rgb-theme-night-main), var(--opacity-background--subtle));
  --color-theme-night-background--active: rgba(var(--rgb-theme-night-main), var(--opacity-background--active));
  --color-theme-night-background--disabled: rgba(var(--rgb-theme-night-main), var(--opacity-background--disabled));
  --color-theme-night-background--hover: rgba(var(--rgb-theme-night-main), var(--opacity-background--hover));

  --color-theme-night-border: rgba(var(--rgb-theme-night-main), var(--opacity-border));

  --color-theme-night-shadow--border: rgba(var(--rgb-theme-night-main), var(--opacity-border));
  --color-theme-night-shadow--light: rgba(var(--rgb-ui-black), var(--opacity-shadow--light));
  --color-theme-night-shadow--heavy: rgba(var(--rgb-ui-black), var(--opacity-shadow--heavy));

  --drop-shadow-theme-night-border: var(--drop-shadow-border) var(--color-theme-night-shadow--border);
  --drop-shadow-theme-night-elevation--x1:
    var(--drop-shadow-elevation--x1) var(--color-theme-night-shadow--light),
    var(--drop-shadow-definition) var(--color-theme-night-shadow--heavy);
  --drop-shadow-theme-night-elevation--x2:
    var(--drop-shadow-elevation--x2) var(--color-theme-night-shadow--light),
    var(--drop-shadow-definition) var(--color-theme-night-shadow--heavy);
}

.ax-theme--night {
  --rgb-theme-main: var(--rgb-theme-night-main);
  --rgb-theme-main--dark: var(--rgb-theme-night-main--dark);
  --rgb-theme-main--darker: var(--rgb-theme-night-main--darker);

  --rgb-theme-background--shade-1: var(--rgb-theme-night-background--shade-1);
  --rgb-theme-background--shade-2: var(--rgb-theme-night-background--shade-2);
  --rgb-theme-background--shade-3: var(--rgb-theme-night-background--shade-3);
  --rgb-theme-background--shade-4: var(--rgb-theme-night-background--shade-4);

  --color-theme-main: var(--color-theme-night-main);
  --color-theme-main--dark: var(--color-theme-night-main--dark);
  --color-theme-main--darker: var(--color-theme-night-main--darker);

  --color-theme-text: var(--color-theme-night-text);
  --color-theme-text--subtle: var(--color-theme-night-text--subtle);
  --color-theme-text--disabled: var(--color-theme-night-text--disabled);

  --color-theme-background--shade-1: var(--color-theme-night-background--shade-1);
  --color-theme-background--shade-2: var(--color-theme-night-background--shade-2);
  --color-theme-background--shade-3: var(--color-theme-night-background--shade-3);
  --color-theme-background--shade-4: var(--color-theme-night-background--shade-4);

  --color-theme-background: var(--color-theme-night-background);
  --color-theme-background--overlay-heavy: var(--color-theme-night-background--overlay-heavy);
  --color-theme-background--overlay-light: var(--color-theme-night-background--overlay-light);
  --color-theme-background--subtle: var(--color-theme-night-background--subtle);
  --color-theme-background--active: var(--color-theme-night-background--active);
  --color-theme-background--disabled: var(--color-theme-night-background--disabled);
  --color-theme-background--hover: var(--color-theme-night-background--hover);

  --color-theme-border: var(--color-theme-night-border);

  --color-theme-shadow--border: var(--color-theme-night-shadow--border);
  --color-theme-shadow--light: var(--color-theme-night-shadow--heavy);
  --color-theme-shadow--heavy: var(--color-theme-night-shadow--light);

  --drop-shadow-theme-border: var(--drop-shadow-theme-night-border);
  --drop-shadow-theme-elevation--x1: var(--drop-shadow-theme-night-elevation--x1);
  --drop-shadow-theme-elevation--x2: var(--drop-shadow-theme-night-elevation--x2);
}
