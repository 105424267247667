.ax-input {
  flex: 1 1 auto;
  border: 0;
  background-color: transparent;
  text-overflow: ellipsis;
  line-height: 1rem;
  line-height: var(--component-line-height)
}

.ax-input:-ms-input-placeholder {
  color: rgba(63, 63, 63, .3);
  color: var(--color-theme-text--disabled);
}

.ax-input::-ms-input-placeholder {
  color: rgba(63, 63, 63, .3);
  color: var(--color-theme-text--disabled);
}

.ax-input::placeholder {
  color: rgba(63, 63, 63, .3);
  color: var(--color-theme-text--disabled);
}

.ax-input:focus {
  outline: none
}

.ax-input:focus:-ms-input-placeholder {
  color: transparent;
}

.ax-input:focus::-ms-input-placeholder {
  color: transparent;
}

.ax-input:focus::placeholder {
  color: transparent;
}

.ax-input__progress {
  padding-right: 0.75rem;
  padding-right: var(--component-padding-horizontal-small);
}

.ax-input__icon--align-right {
  order: 1;
}

.ax-input__icon-container--small .ax-input {
  padding: 0.1875rem 0.75rem;
  padding: var(--component-padding-vertical-small) var(--component-padding-horizontal-small);
  font-size: 0.75rem;
  font-size: var(--font-size-small);
}

.ax-input__icon-container--medium .ax-input {
  padding: 0.4375rem 0.75rem;
  padding: var(--component-padding-vertical-medium) var(--component-padding-horizontal-medium);
  font-size: 0.75rem;
  font-size: var(--font-size-small);
}

.ax-input__icon-container--large .ax-input {
  padding: 0.6875rem 1rem;
  padding: var(--component-padding-vertical-large) var(--component-padding-horizontal-large);
  font-size: 0.875rem;
  font-size: var(--font-size-large);
}

.ax-input:-webkit-autofill,
.ax-input:-webkit-autofill:hover,
.ax-input:-webkit-autofill:focus,
.ax-input:-webkit-autofill:active {
  animation-name: inputAutofillFix;
  animation-fill-mode: forwards;
}

.ax-input__icon-container {
  display: flex;
  position: relative;
  flex: 1 1 auto;
  align-items: center;
  border-width: 0.0625rem;
  border-width: var(--component-border-width);
  border-style: solid;
  border-radius: 0.1875rem;
  border-radius: var(--component-border-radius);
  border-color: rgba(63, 63, 63, .2);
  border-color: var(--color-theme-border);
  background-color: rgb(255, 255, 255);
  background-color: var(--color-theme-background);
  transition-property: border-color;
  transition-duration: 200ms;
  transition-duration: var(--transition-time-base);
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition-timing-function: var(--transition-function)
}

.ax-input__icon-container .ax-input__icon--align-left {
  padding-left: 0.5rem;
  padding-left: var(--spacing-grid-size--x2);
}

.ax-input__icon-container .ax-input__icon--align-right {
  padding-right: 0.5rem;
  padding-right: var(--spacing-grid-size--x2);
}

.ax-input__icon-container .ax-input__icon--align-left + .ax-input {
  padding-left: 0.25rem;
  padding-left: var(--spacing-grid-size);
}

.ax-input__icon-container .ax-input__icon--align-right + .ax-input {
  padding-right: 0.25rem;
  padding-right: var(--spacing-grid-size);
}

.ax-input__icon-container:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ax-input__icon-container--disabled {
  background-color: rgba(63, 63, 63, .05);
  background-color: var(--color-theme-background--disabled);
}

.ax-input__icon-container--focused {
  border-color: rgba(35, 158, 219, .5);
  border-color: rgba(var(--rgb-ui-accent), var(--opacity-input-border--focused));
}

.ax-input__icon-container--overlay {
  border-color: transparent;
  background-color: rgba(63, 63, 63, .15);
  background-color: var(--color-theme-background--subtle)
}

.ax-input__icon-container--overlay .ax-input:-ms-input-placeholder {
  color: rgba(63, 63, 63, .75);
  color: var(--color-theme-text--subtle);
}

.ax-input__icon-container--overlay .ax-input::-ms-input-placeholder {
  color: rgba(63, 63, 63, .75);
  color: var(--color-theme-text--subtle);
}

.ax-input__icon-container--overlay .ax-input::placeholder {
  color: rgba(63, 63, 63, .75);
  color: var(--color-theme-text--subtle);
}

.ax-input__icon-container--valid:not(.ax-input__icon-container--focused) {
  border-color: rgba(252, 72, 112, .4);
  border-color: rgba(var(--rgb-ui-error), var(--opacity-input-border--valid));
}

.ax-input__icon-container--invalid:not(.ax-input__icon-container--focused) {
  border-color: rgba(252, 72, 112, .25);
  border-color: rgba(var(--rgb-ui-error), var(--opacity-input-border--invalid));
  color: rgb(252, 72, 112);
  color: var(--color-ui-error)
}

.ax-input__icon-container--invalid:not(.ax-input__icon-container--focused) .ax-input:-ms-input-placeholder {
  color: rgb(252, 72, 112);
  color: var(--color-ui-error);
}

.ax-input__icon-container--invalid:not(.ax-input__icon-container--focused) .ax-input::-ms-input-placeholder {
  color: rgb(252, 72, 112);
  color: var(--color-ui-error);
}

.ax-input__icon-container--invalid:not(.ax-input__icon-container--focused) .ax-input::placeholder {
  color: rgb(252, 72, 112);
  color: var(--color-ui-error);
}

.ax-input__icon-container--light .ax-input:focus:-ms-input-placeholder, .ax-input__icon-container--dark .ax-input:focus:-ms-input-placeholder {
  color: transparent;
}

.ax-input__icon-container--light .ax-input:focus::-ms-input-placeholder, .ax-input__icon-container--dark .ax-input:focus::-ms-input-placeholder {
  color: transparent;
}

.ax-input__icon-container--light .ax-input:focus::placeholder, .ax-input__icon-container--dark .ax-input:focus::placeholder {
  color: transparent;
}

.ax-input__container {
  display: flex;
}

.ax-input__wrapper--target {
  cursor: pointer;
}
