:root {
  --cmp-input-checkbox-size: 1rem;
  --cmp-input-checkbox-size: calc(var(--spacing-grid-size) * 4);
  --cmp-chedio-indicator-space: 0.5rem;
  --cmp-chedio-indicator-space: calc(var(--spacing-grid-size) * 2);
  --cmp-checkbox-thickness: 0.125rem;
  --cmp-checkbox-thickness: calc(var(--cmp-input-checkbox-size) * 0.125);
  --cmp-checkbox-padding-top: 0.25rem;
  --cmp-checkbox-padding-top: calc(var(--cmp-input-checkbox-size) * 0.25);
  --cmp-checkbox-padding-bottom: 0.375rem;
  --cmp-checkbox-padding-bottom: calc(var(--cmp-input-checkbox-size) * 0.375);
  --cmp-checkbox-padding-horizontal: 0.25rem;
  --cmp-checkbox-padding-horizontal: calc(var(--cmp-input-checkbox-size) * 0.25);
  --cmp-checkbox-indeterminate-top:
    0.375rem;
  --cmp-checkbox-indeterminate-top:
    calc(var(--cmp-input-checkbox-size) * 0.5 - var(--cmp-checkbox-thickness));
  --cmp-checkbox-indeterminate-horizontal: 0.2rem;
  --cmp-checkbox-indeterminate-horizontal: calc(var(--cmp-input-checkbox-size) * 0.2);
}

.ax-checkbox,
.ax-radio {
  display: flex;
  align-items: center;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.ax-checkbox--disabled,
.ax-radio--disabled {
  cursor: default;
}

.ax-checkbox__input,
.ax-radio__input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.ax-checkbox__indicator,
.ax-radio__indicator {
  position: relative;
  width: 1rem;
  width: var(--cmp-input-checkbox-size);
  height: 1rem;
  height: var(--cmp-input-checkbox-size);
  border: 0.0625rem solid rgba(63, 63, 63, .2);
  border: var(--component-border-width) solid var(--color-theme-border);
  background-color: rgb(255, 255, 255);
  background-color: var(--color-theme-background);
  transition-property: background-color, border-color, color;
  transition-duration: 200ms;
  transition-duration: var(--transition-time-base);
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition-timing-function: var(--transition-function)
}

.ax-checkbox__indicator::before, .ax-radio__indicator::before {
  content: '';
}

.ax-radio__indicator {
  border-radius: 50%
}

.ax-radio__indicator::before {
  position: absolute;
  top: 0.25rem;
  top: calc(var(--cmp-input-checkbox-size) * 0.25);
  right: 0.25rem;
  right: calc(var(--cmp-input-checkbox-size) * 0.25);
  bottom: 0.25rem;
  bottom: calc(var(--cmp-input-checkbox-size) * 0.25);
  left: 0.25rem;
  left: calc(var(--cmp-input-checkbox-size) * 0.25);
  border-radius: 50%;
  transition-property: background-color;
  transition-duration: 200ms;
  transition-duration: var(--transition-time-base);
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition-timing-function: var(--transition-function);
}

.ax-checkbox__indicator {
  border-radius: 0.1875rem;
  border-radius: var(--component-border-radius)
}

.ax-checkbox__indicator::before {
  position: absolute;
  top: 0.25rem;
  top: var(--cmp-checkbox-padding-top);
  right: 0.25rem;
  right: var(--cmp-checkbox-padding-horizontal);
  bottom: 0.375rem;
  bottom: var(--cmp-checkbox-padding-bottom);
  left: 0.25rem;
  left: var(--cmp-checkbox-padding-horizontal);
  transform: rotate(-45deg);
  border-bottom: 0.125rem solid;
  border-bottom: var(--cmp-checkbox-thickness) solid;
  border-left: 0.125rem solid;
  border-left: var(--cmp-checkbox-thickness) solid;
  transition-property: border-color;
  transition-duration: 200ms;
  transition-duration: var(--transition-time-base);
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition-timing-function: var(--transition-function);
}

.ax-checkbox__label,
.ax-radio__label {
  margin-left: 0.5rem;
  margin-left: var(--cmp-chedio-indicator-space);
  transition-property: color;
  transition-duration: 200ms;
  transition-duration: var(--transition-time-base);
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition-timing-function: var(--transition-function);
}

.ax-checkbox__input:disabled + .ax-checkbox__indicator,
.ax-radio__input:disabled + .ax-radio__indicator {
  background-color: rgba(63, 63, 63, .05);
  background-color: var(--color-theme-background--disabled);
}

.ax-checkbox__input:checked:enabled + .ax-checkbox__indicator,
.ax-radio__input:checked:enabled + .ax-radio__indicator {
  border-color: transparent;
  background-color: rgb(35, 158, 219);
  background-color: var(--color-ui-accent);
}

.ax-checkbox__input:disabled ~ .ax-checkbox__label,
.ax-radio__input:disabled ~ .ax-radio__label {
  color: rgba(63, 63, 63, .3);
  color: var(--color-theme-text--disabled);
}

.ax-checkbox__input:active:enabled + .ax-checkbox__indicator,
.ax-radio__input:active:enabled + .ax-radio__indicator {
  border-color: rgba(35, 158, 219, .5);
  border-color: rgba(var(--rgb-ui-accent), var(--opacity-input-border--focused));
}

.ax-checkbox--invalid .ax-checkbox__input:enabled + .ax-checkbox__indicator,
.ax-checkbox--invalid .ax-checkbox__input:focus + .ax-checkbox__indicator,
.ax-radio--invalid .ax-radio__input:enabled + .ax-radio__indicator,
.ax-radio--invalid .ax-radio__input:focus + .ax-radio__indicator {
  border-color: rgba(252, 72, 112, .25);
  border-color: rgba(var(--rgb-ui-error), var(--opacity-input-border--invalid));
}

.ax-checkbox--invalid .ax-checkbox__input:checked:enabled + .ax-checkbox__indicator,
.ax-radio--invalid .ax-radio__input:checked:enabled + .ax-radio__indicator {
  background-color: rgb(252, 72, 112);
  background-color: var(--color-ui-error);
}

.ax-checkbox--invalid .ax-checkbox__input:active:enabled + .ax-checkbox__indicator,
.ax-radio--invalid .ax-radio__input:active:enabled + .ax-radio__indicator {
  border-color: rgba(252, 72, 112, .5);
  border-color: rgba(var(--rgb-ui-error), var(--opacity-input-border--focused));
}

.ax-checkbox--invalid .ax-checkbox__input:enabled ~ .ax-checkbox__label,
.ax-radio--invalid .ax-radio__input:enabled ~ .ax-radio__label {
  color: rgb(252, 72, 112);
  color: var(--color-ui-error);
}

.ax-checkbox__input:checked:active:enabled + .ax-checkbox__indicator,
.ax-radio__input:checked:active:enabled + .ax-radio__indicator {
  background-color: rgb(255, 255, 255);
  background-color: var(--color-theme-background);
}

/* stylelint-disable no-duplicate-selectors */
.ax-radio__input + .ax-radio__indicator::before {
  background-color: transparent;
}
.ax-radio__input:checked + .ax-radio__indicator::before {
  background-color: rgb(248, 248, 248);
  background-color: var(--color-ui-white-noise);
}
.ax-radio__input:checked:active:enabled + .ax-radio__indicator::before {
  background-color: rgb(35, 158, 219);
  background-color: var(--color-ui-accent);
}
.ax-radio__input:disabled + .ax-radio__indicator::before {
  background-color: transparent;
}
.ax-radio__input:disabled:checked + .ax-radio__indicator::before {
  background-color: rgba(63, 63, 63, .2);
  background-color: var(--color-theme-border);
}
.ax-radio--invalid .ax-radio__input:checked + .ax-radio__indicator::before {
  border-color: rgb(252, 72, 112);
  border-color: var(--color-ui-error);
}
.ax-radio--invalid .ax-radio__input:checked:active:enabled + .ax-radio__indicator::before {
  background-color: rgb(252, 72, 112);
  background-color: var(--color-ui-error);
}

.ax-checkbox__input + .ax-checkbox__indicator::before {
  border-color: transparent;
}

.ax-checkbox__input:checked + .ax-checkbox__indicator::before {
  border-color: rgb(248, 248, 248);
  border-color: var(--color-ui-white-noise);
}

.ax-checkbox__input:checked:active:enabled + .ax-checkbox__indicator::before {
  border-color: rgb(35, 158, 219);
  border-color: var(--color-ui-accent);
}

.ax-checkbox__input:disabled + .ax-checkbox__indicator::before {
  border-color: transparent;
}

.ax-checkbox__input:disabled:checked + .ax-checkbox__indicator::before {
  border-color: rgba(63, 63, 63, .2);
  border-color: var(--color-theme-border);
}

.ax-checkbox--indeterminate .ax-checkbox__indicator::before {
  top: 0.375rem;
  top: var(--cmp-checkbox-indeterminate-top);
  right: 0.2rem;
  right: var(--cmp-checkbox-indeterminate-horizontal);
  left: 0.2rem;
  left: var(--cmp-checkbox-indeterminate-horizontal);
  transform: rotate(0deg);
  border-left: 0;
}

.ax-checkbox--invalid .ax-checkbox__input:checked:active:enabled + .ax-checkbox__indicator::before {
  border-color: rgb(252, 72, 112);
  border-color: var(--color-ui-error);
}
