:root {
  --cmp-inline-spacing: 0.5rem;
  --cmp-inline-spacing: calc(var(--spacing-grid-size) * 2);
}

.ax-inline-group__spacer {
  margin-bottom: -0.5rem;
  margin-bottom: calc(var(--cmp-inline-spacing) * -1)

  /* stylelint-disable selector-max-universal */
}

.ax-inline-group__spacer > * {
  margin-right: 0.5rem;
  margin-right: var(--cmp-inline-spacing);
  margin-bottom: 0.5rem;
  margin-bottom: var(--cmp-inline-spacing);
}

.ax-inline-group__spacer > *:last-child {
  margin-right: 0;
}

.ax-inline-group__spacer {

  /* stylelint-enable */
}
