:root {
  --ghost-width: 8.25rem;
  --ghost-character-width: 3rem;
  --ghost-vertical-position: 3.75rem;

  --ghost-light-blue: rgb(199, 230, 245);
  --ghost-dark-blue: rgb(35, 158, 219);
  --ghost-gray-dark: rgb(220, 220, 220);
  --ghost-gray-light: rgb(248, 248, 248);

  --ghost-offset:
    2.625rem;

  --ghost-offset:
    calc(var(--ghost-width) / 2 - var(--ghost-character-width) / 2);
}

.ax-ghost {
  display: block;
  width: 8.25rem;
  width: var(--ghost-width);
  height: 11.625rem;
}

.ax-ghost__character {
  transform: translate(2.625rem, 4.25rem);
  transform: translate(var(--ghost-offset), calc(var(--ghost-vertical-position) + 0.5rem));
  opacity: 0;
  animation: ghost-appear 1s 0.5s cubic-bezier(0.25, 0.1, 0.25, 1) 1 forwards;
}

.ax-ghost__eyelid {
  fill: rgb(199, 230, 245);
  fill: var(--ghost-light-blue);
  animation: ghost-blink 8s -5s steps(1, end) infinite forwards;
}

.ax-ghost__background { fill: rgb(35, 158, 219); fill: var(--ghost-dark-blue); }
.ax-ghost__body { fill: rgb(199, 230, 245); fill: var(--ghost-light-blue); }
.ax-ghost__card { fill: rgb(248, 248, 248); fill: var(--ghost-gray-light); }
.ax-ghost__card-subtitle { fill: rgb(35, 158, 219); fill: var(--ghost-dark-blue); }
.ax-ghost__card-title { fill: rgb(220, 220, 220); fill: var(--ghost-gray-dark); }
.ax-ghost__eye { fill: rgb(35, 158, 219); fill: var(--ghost-dark-blue); }
.ax-ghost__text { fill: rgb(220, 220, 220); fill: var(--ghost-gray-dark); }

@keyframes ghost-appear {
  to {
    transform: translate(2.625rem, 3.75rem);
    transform: translate(var(--ghost-offset), var(--ghost-vertical-position));
    opacity: 1;
  }
}

/* 6000ms blinks cycle, 150ms blink, 300ms pause */
@keyframes ghost-blink {
  /* blink start: 7400 / 8000 */
  92.5% { transform: translateY(0.5rem); }

  /* blink end: 7550 / 8000 */
  94.375% { transform: translateY(0); }

  /* blink start: 7850 / 8000 */
  98.125% { transform: translateY(0.5rem); }

  /* blink start: 8000 / 8000 */
  100% { transform: translateY(0); }
}
