.ax-notifications {
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  flex-direction: column;
  max-height: 100vh;
  padding-bottom: 1.5rem;
  padding-bottom: var(--page-padding-vertical);
  overflow-y: auto;
  z-index: 6;
  z-index: var(--z-index-notifications);
}

.ax-notifications__notification {
  margin: 1.5rem 1.5rem 0;
  margin: var(--page-padding-vertical) var(--page-padding-horizontal) 0;
}

.ax-notification {
  display: flex;
  max-width: 23rem;
  max-width: var(--layout-width-tiny);
  border-radius: 0.1875rem;
  border-radius: var(--component-border-radius);
  box-shadow: 0 0 0 0.0625rem rgba(63, 63, 63, .2), 0 0.25rem 0.5625rem 0 rgba(63, 63, 63, .14),
    0 0.0625rem 0.125rem 0.03125rem rgba(63, 63, 63, .15);
  box-shadow: var(--drop-shadow-theme-day-border), var(--drop-shadow-theme-day-elevation--x2);
  background-color: rgb(255, 255, 255);
  background-color: var(--color-theme-day-background);
  color: rgb(63, 63, 63);
  color: var(--color-theme-day-text);
}

.ax-notification__icon {
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  padding: 1rem 1rem;
  padding: var(--spacing-grid-size--x4) var(--spacing-grid-size--x4);
  border-right: 0.0625rem solid rgba(63, 63, 63, .2);
  border-right: 0.0625rem solid var(--color-theme-day-border)
}

.ax-notification--error .ax-notification__icon {
  color: rgb(252, 72, 112);
  color: var(--color-ui-error);
}

.ax-notification--info .ax-notification__icon {
  color: rgb(35, 158, 219);
  color: var(--color-ui-info);
}

.ax-notification--success .ax-notification__icon {
  color: rgb(0, 190, 150);
  color: var(--color-ui-success);
}

.ax-notification--warning .ax-notification__icon {
  color: rgb(218, 174, 69);
  color: var(--color-ui-warning);
}

.ax-notification__messsage {
  flex: 1 1 auto;
  -ms-grid-row-align: center;
      align-self: center;
  padding: 1rem 1rem;
  padding: var(--spacing-grid-size--x4) var(--spacing-grid-size--x4);
}

.ax-notification__remove {
  flex: 0 0 auto;
  padding: 0.75rem 0.75rem 0.75rem 0;
  padding: var(--spacing-grid-size--x3) var(--spacing-grid-size--x3) var(--spacing-grid-size--x3) 0;
}
