.ax-menu {
  display: flex;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  overflow: auto;
}

.ax-menu__item {
  display: inline-flex;
  margin: 0 1.5rem 0 0;
  margin: 0 var(--spacing-grid-size--x6) 0 0;
  list-style: none
}

.ax-menu__item:last-child {
  margin-right: 0;
}

.ax-menu__item-button {
  display: flex;
  flex: 0 1 auto;
  align-items: center;
  justify-content: center;
  padding: 0 0 0.75rem;
  padding: 0 0 var(--spacing-grid-size--x3);
  outline: 0;
  border: 0;
  border-bottom: 0.25rem solid transparent;
  border-bottom: var(--spacing-grid-size) solid transparent;
  background: none;
  color: inherit;
  transition-property: border-bottom-color, color;
  transition-duration: 200ms;
  transition-duration: var(--transition-time-base);
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition-timing-function: var(--transition-function);
}

/* stylelint-disable no-descending-specificity */
.ax-menu__item:hover .ax-menu__item-button {
  color: rgb(48, 171, 232);
  color: var(--color-ui-accent--hover);
  cursor: pointer;
}

.ax-menu__item--active .ax-menu__item-button,
.ax-menu__item--active:hover .ax-menu__item-button {
  border-bottom-color: rgb(22, 145, 207);
  border-bottom-color: var(--color-ui-accent--active);
  color: inherit;
}

.ax-menu__item--disabled .ax-menu__item-button,
.ax-menu__item--disabled:hover .ax-menu__item-button {
  border-bottom-color: transparent;
  color: rgba(63, 63, 63, .3);
  color: var(--color-theme-text--disabled);
  cursor: default;
}
/* stylelint-enable */
