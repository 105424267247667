.ax-radial-progress {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.ax-radial-progress--small {
  width: 1.5rem;
  width: var(--component-round-size-small);
  height: 1.5rem;
  height: var(--component-round-size-small);
}

.ax-radial-progress--medium {
  width: 3rem;
  width: var(--component-round-size-medium);
  height: 3rem;
  height: var(--component-round-size-medium);
}

.ax-radial-progress--large {
  width: 4.5rem;
  width: var(--component-round-size-large);
  height: 4.5rem;
  height: var(--component-round-size-large);
}
