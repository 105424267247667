.ax-chip {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: var(--cmp-inline-spacing);
  margin-right: var(--cmp-inline-spacing);
  margin-bottom: var(--cmp-inline-spacing);
  margin-bottom: var(--cmp-inline-spacing);
  padding: 0;
  outline: 0;
  border-width: 0.0625rem;
  border-width: var(--component-border-width);
  border-style: solid;
  border-radius: 1.25rem;
  border-radius: var(--component-border-radius-huge);
  border-color: transparent;
  background-color: rgb(248, 248, 248);
  background-color: var(--color-ui-white-noise);
  color: rgb(63, 63, 63);
  color: var(--color-ui-carbon);
  letter-spacing: 0.001875rem;
  letter-spacing: var(--letter-spacing-loose);
  text-align: center;
  line-height: 1rem;
  line-height: var(--component-line-height);
  white-space: nowrap;
  vertical-align: middle;
  transition-property: background-color, border-color, color;
  transition-duration: 200ms;
  transition-duration: var(--transition-time-base);
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition-timing-function: var(--transition-function)
}

.ax-chip:hover {
  background-color: rgb(238, 238, 238);
  background-color: var(--color-ui-white-noise--dark);
  cursor: pointer;
}

.ax-chip[disabled],
  .ax-chip[disabled]:hover,
  .ax-chip[disabled]:active {
  cursor: default;
}

.ax-chip[disabled]:hover {
  background-color: rgb(248, 248, 248);
  background-color: var(--color-ui-white-noise);
}

.ax-chip-metric {
  margin-right: 0.25rem;
  margin-right: var(--spacing-grid-size--x1);
  color: rgba(63, 63, 63, .75);
  color: var(--color-theme-text--subtle);
}

.ax-chip-metric--light {
  color: rgb(255, 255, 255);
  color: var(--color-ui-white);
}

.ax-chip--active {
  background-color: rgb(35, 158, 219);
  background-color: var(--color-ui-accent);
  color: rgb(255, 255, 255);
  color: var(--color-ui-white)
}

.ax-chip--active:hover {
  background-color: rgb(48, 171, 232);
  background-color: var(--color-ui-accent--hover);
}

.ax-chip--active[disabled]:hover {
  background-color: rgb(35, 158, 219);
  background-color: var(--color-ui-accent);
}

.ax-chip-label {
  margin: 0 0.5rem 0 0.5rem;
  margin: 0 var(--spacing-grid-size--x2) 0 var(--spacing-grid-size--x2);
}

.ax-chip--small {
  padding: 0.1875rem 0.75rem;
  padding: var(--component-padding-vertical-small) var(--component-padding-horizontal-small);
  font-size: 0.75rem;
  font-size: var(--font-size-small);
}

.ax-chip--medium {
  padding: 0.4375rem 0.75rem;
  padding: var(--component-padding-vertical-medium) var(--component-padding-horizontal-medium);
  font-size: var(--font-size-medium);
  font-size: var(--font-size-medium);
}

.ax-chip--large {
  padding: 0.6875rem 1rem;
  padding: var(--component-padding-vertical-large) var(--component-padding-horizontal-large);
  font-size: 0.875rem;
  font-size: var(--font-size-large);
}
