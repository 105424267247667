.ax-alert-icon {
  display: flex;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
  border-radius: 50%;
  line-height: 0;
}

.ax-alert-icon--small {
  width: 1.5rem;
  width: var(--component-round-size-small);
  height: 1.5rem;
  height: var(--component-round-size-small);
}

.ax-alert-icon--medium {
  width: 3rem;
  width: var(--component-round-size-medium);
  height: 3rem;
  height: var(--component-round-size-medium);
}

.ax-alert-icon--large {
  width: 4.5rem;
  width: var(--component-round-size-large);
  height: 4.5rem;
  height: var(--component-round-size-large);
}

.ax-alert-icon--primary {
  color: rgb(248, 248, 248);
  color: var(--color-ui-white-noise)
}

.ax-alert-icon--primary.ax-alert-icon--success {
  background-color: rgb(0, 190, 150);
  background-color: var(--color-ui-success);
}

.ax-alert-icon--primary.ax-alert-icon--warning {
  background-color: rgb(218, 174, 69);
  background-color: var(--color-ui-warning);
}

.ax-alert-icon--primary.ax-alert-icon--error {
  background-color: rgb(252, 72, 112);
  background-color: var(--color-ui-error);
}

.ax-alert-icon--primary.ax-alert-icon--info {
  background-color: rgb(35, 158, 219);
  background-color: var(--color-ui-info);
}

.ax-alert-icon--secondary {
  background-color: rgba(63, 63, 63, .08);
  background-color: var(--color-theme-background--active)
}

.ax-alert-icon--secondary.ax-alert-icon--success {
  color: rgb(0, 190, 150);
  color: var(--color-ui-success);
}

.ax-alert-icon--secondary.ax-alert-icon--warning {
  color: rgb(218, 174, 69);
  color: var(--color-ui-warning);
}

.ax-alert-icon--secondary.ax-alert-icon--error {
  color: rgb(252, 72, 112);
  color: var(--color-ui-error);
}

.ax-alert-icon--secondary.ax-alert-icon--info {
  color: rgb(35, 158, 219);
  color: var(--color-ui-info);
}

.ax-alert-icon--subtle {
  background-color: rgba(63, 63, 63, .3);
  background-color: var(--color-theme-background--overlay-light);
  color: rgb(248, 248, 248);
  color: var(--color-ui-white-noise);
}
