.ax-dock__list-header,
.ax-dock__list-footer {
  margin: 0;
  padding: 0;
}

.ax-dock__list-footer {
  margin-top: auto;
}

.ax-dock__item {
  display: flex;
  flex-direction: column;
  width: var(--cmp-platform-dock-size);
  width: var(--cmp-platform-dock-size);
  height: var(--cmp-platform-dock-size);
  height: var(--cmp-platform-dock-size);
}

.ax-dock__item-wrapper {
  display: flex;
  flex: 1 0 0%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.ax-dock__icon-link {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  color: rgb(248, 248, 248);
  color: var(--color-theme-night-text);
  transition-property: background-color, color;
  transition-duration: 200ms;
  transition-duration: var(--transition-time-base);
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition-timing-function: var(--transition-function)
}

.ax-dock__icon-link:hover {
  color: rgb(48, 171, 232);
  color: var(--color-ui-accent--hover);
}

.ax-dock__icon-link:active {
  color: rgb(35, 158, 219);
  color: var(--color-ui-accent);
}

.ax-dock__icon-link--active,
.ax-dock__icon-link--active:hover {
  background-color: rgb(35, 158, 219);
  background-color: var(--color-ui-accent);
  color: rgb(248, 248, 248);
  color: var(--color-ui-white-noise);
}
