.ax-label {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-width: 0.0625rem;
  border-width: var(--component-border-width);
  border-style: solid;
  border-radius: 0.1875rem;
  border-radius: var(--component-border-radius);
  background-color: transparent;
  text-align: center;
  line-height: 1rem;
  line-height: var(--component-line-height);
  white-space: nowrap;
  vertical-align: middle;
}

.ax-label--small {
  padding: 0.1875rem 0.75rem;
  padding: var(--component-padding-vertical-small) var(--component-padding-horizontal-small);
  font-size: 0.75rem;
  font-size: var(--font-size-small);
}

.ax-label--medium {
  padding: 0.4375rem 0.75rem;
  padding: var(--component-padding-vertical-medium) var(--component-padding-horizontal-medium);
  font-size: 0.875rem;
  font-size: var(--font-size-large);
}

.ax-label--full {
  width: 100%;
}

@media (max-width: 47.9375rem) {
  .ax-label--full--small { width: 100%; }
}

@media (max-width: 61.9375rem) {
  .ax-label--full--medium { width: 100%; }
}

@media (max-width: 74.9375rem) {
  .ax-label--full--large { width: 100%; }
}

.ax-label--white {
  border-color: rgba(63, 63, 63, .2);
  border-color: var(--color-theme-border);
  background-color: rgb(255, 255, 255);
  background-color: var(--color-ui-white);
  color: rgb(63, 63, 63);
  color: var(--color-theme-day-text);
}

.ax-label--success {
  border-color: transparent;
  background-color: rgb(0, 190, 150);
  background-color: var(--color-ui-success);
  color: rgb(248, 248, 248);
  color: var(--color-theme-night-text);
}

.ax-label--error {
  border-color: transparent;
  background-color: rgb(252, 72, 112);
  background-color: var(--color-ui-error);
  color: rgb(248, 248, 248);
  color: var(--color-theme-night-text);
}
