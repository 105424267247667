.ax-reveal {
  transition-property: height, opacity, margin;
  transition-duration: 400ms;
  transition-duration: var(--transition-time-slow);
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition-timing-function: var(--transition-function);
}

.ax-reveal__inner {
  transform: translateY(25%);
  transition-property: transform;
  transition-duration: 400ms;
  transition-duration: var(--transition-time-slow);
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition-timing-function: var(--transition-function);
}

.ax-reveal--visible .ax-reveal__inner {
  transform: translateY(0%);
}
