:root {
  /**
   * The designs of the medium tip is 1.5rem from corner to corner. To calculate
   * the pre-rotated square we use `Math.sqrt((1.5rem**2)/2)`
   */
  --cmp-tip-size--medium: 1.0606601717798212rem;

  --cmp-tip-size--small: 0.75rem;
}

.ax-tip__content {
  background-color: rgb(255, 255, 255);
  background-color: var(--color-theme-background);
  /* stylelint-disable */
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  /* stylelint-enable */
}

.ax-tip--shadow .ax-tip__content {
  background-color: transparent;
}

.ax-tip__arrow--small {
  width: 0.75rem;
  width: var(--cmp-tip-size--small);
  height: 0.75rem;
  height: var(--cmp-tip-size--small);
  margin: 0.75rem;
  margin: var(--cmp-tip-size--small)
}

.ax-tip--top .ax-tip__arrow--small {
  bottom: -0.375rem;
  bottom: calc(var(--cmp-tip-size--small) * -0.5);
}

.ax-tip--right .ax-tip__arrow--small {
  left: -0.375rem;
  left: calc(var(--cmp-tip-size--small) * -0.5);
}

.ax-tip--bottom .ax-tip__arrow--small {
  top: -0.375rem;
  top: calc(var(--cmp-tip-size--small) * -0.5);
}

.ax-tip--left .ax-tip__arrow--small {
  right: -0.375rem;
  right: calc(var(--cmp-tip-size--small) * -0.5);
}

.ax-tip__arrow--medium {
  width: 1.0606601717798212rem;
  width: var(--cmp-tip-size--medium);
  height: 1.0606601717798212rem;
  height: var(--cmp-tip-size--medium);
  margin: 1.0606601717798212rem;
  margin: var(--cmp-tip-size--medium)
}

.ax-tip--top .ax-tip__arrow--medium {
  bottom: -0.53033rem;
  bottom: calc(var(--cmp-tip-size--medium) * -0.5);
}

.ax-tip--right .ax-tip__arrow--medium {
  left: -0.53033rem;
  left: calc(var(--cmp-tip-size--medium) * -0.5);
}

.ax-tip--bottom .ax-tip__arrow--medium {
  top: -0.53033rem;
  top: calc(var(--cmp-tip-size--medium) * -0.5);
}

.ax-tip--left .ax-tip__arrow--medium {
  right: -0.53033rem;
  right: calc(var(--cmp-tip-size--medium) * -0.5);
}

.ax-tip__arrow {
  position: absolute;
  transform: rotate(45deg);
  background-color: rgb(255, 255, 255);
  background-color: var(--color-theme-background);
  pointer-events: none
}

.ax-tip--faded .ax-tip__arrow {
  background-color: rgb(63, 63, 63);
  background-color: var(--color-theme-text);
}

.ax-tip--success .ax-tip__arrow {
  background-color: rgb(0, 190, 150);
  background-color: var(--color-ui-success);
}

.ax-tip--warning .ax-tip__arrow {
  background-color: rgb(218, 174, 69);
  background-color: var(--color-ui-warning);
}

.ax-tip--error .ax-tip__arrow {
  background-color: rgb(252, 72, 112);
  background-color: var(--color-ui-error);
}

.ax-tip--info .ax-tip__arrow {
  background-color: rgb(35, 158, 219);
  background-color: var(--color-ui-info);
}

.ax-tip--shade-1 .ax-tip__arrow {
  background-color: rgb(255, 255, 255);
  background-color: var(--color-theme-background--shade-1);
}

.ax-tip--shade-2 .ax-tip__arrow {
  background-color: rgb(248, 248, 248);
  background-color: var(--color-theme-background--shade-2);
}

.ax-tip--shade-3 .ax-tip__arrow {
  background-color: rgb(238, 238, 238);
  background-color: var(--color-theme-background--shade-3);
}

.ax-tip--shade-4 .ax-tip__arrow {
  background-color: rgb(228, 228, 228);
  background-color: var(--color-theme-background--shade-4);
}

.ax-tip--carbon .ax-tip__arrow {
  background-color: rgb(63, 63, 63);
  background-color: var(--color-ui-carbon);
}

.ax-tip--white .ax-tip__arrow {
  background-color: rgb(255, 255, 255);
  background-color: var(--color-ui-white);
}

.ax-tip--shadow .ax-tip__arrow {
  box-shadow: 0 0 0 0.0625rem rgba(63, 63, 63, .2), 0 0.25rem 0.5625rem 0 rgba(63, 63, 63, .14),
    0 0.0625rem 0.125rem 0.03125rem rgba(63, 63, 63, .15);
  box-shadow: var(--drop-shadow-theme-border), var(--drop-shadow-theme-elevation--x2);
}

.ax-tip--top .ax-tip__arrow--small {
  transform: translateY(0.75rem) rotate(45deg);
  transform: translateY(var(--cmp-tip-size--small)) rotate(45deg);
}

.ax-tip--top .ax-tip__arrow--medium {
  transform: translateY(1.0606601717798212rem) rotate(45deg);
  transform: translateY(var(--cmp-tip-size--medium)) rotate(45deg);
}

.ax-tip--right .ax-tip__arrow--small {
  transform: translateX(-0.75rem) rotate(45deg);
  transform: translateX(calc(var(--cmp-tip-size--small) * -1)) rotate(45deg);
}

.ax-tip--right .ax-tip__arrow--medium {
  transform: translateX(-1.06066rem) rotate(45deg);
  transform: translateX(calc(var(--cmp-tip-size--medium) * -1)) rotate(45deg);
}

.ax-tip--bottom .ax-tip__arrow--small {
  transform: translateY(-0.75rem) rotate(45deg);
  transform: translateY(calc(var(--cmp-tip-size--small) * -1)) rotate(45deg);
}

.ax-tip--bottom .ax-tip__arrow--medium {
  transform: translateY(-1.06066rem) rotate(45deg);
  transform: translateY(calc(var(--cmp-tip-size--medium) * -1)) rotate(45deg);
}

.ax-tip--left .ax-tip__arrow--small {
  transform: translateX(0.75rem) rotate(45deg);
  transform: translateX(var(--cmp-tip-size--small)) rotate(45deg);
}

.ax-tip--left .ax-tip__arrow--medium {
  transform: translateX(1.0606601717798212rem) rotate(45deg);
  transform: translateX(var(--cmp-tip-size--medium)) rotate(45deg);
}
