.ax-dialog {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  max-height: 100%;
  border-radius: 0.1875rem;
  border-radius: var(--component-border-radius);
  box-shadow: 0 0 0 0.0625rem rgba(63, 63, 63, .2), 0 0.25rem 0.5625rem 0 rgba(63, 63, 63, .14),
    0 0.0625rem 0.125rem 0.03125rem rgba(63, 63, 63, .15);
  box-shadow: var(--drop-shadow-theme-border), var(--drop-shadow-theme-elevation--x2);
  background-color: rgb(255, 255, 255);
  background-color: var(--color-theme-background);
  color: rgb(63, 63, 63);
  color: var(--color-theme-text);
}

.ax-dialog__body,
.ax-dialog__header {
  padding: 1.5rem 1.5rem;
  padding: var(--page-padding-vertical) var(--page-padding-horizontal);
}

.ax-dialog__header {
  border-bottom: 0.0625rem solid rgba(63, 63, 63, .2);
  border-bottom: 0.0625rem solid var(--color-theme-border);
}

.ax-dialog__body {
  flex: 1 1 auto;
  overflow: auto;
}

.ax-dialog__footer {
  padding: 0 1.5rem 1.5rem;
  padding: 0 var(--page-padding-vertical) var(--page-padding-horizontal);
}

.ax-dialog__footer-image {
  padding: 0 1.5rem 0 1.5rem;
  padding: 0 var(--page-padding-horizontal) 0 var(--page-padding-horizontal);
}

.ax-dialog__header,
.ax-dialog__footer {
  flex: 0 1 auto;
}

.ax-dialog--fullscreen {
  width: 100vw;
  height: 100vh;
}
