:root {
  --cmp-grid-gutter-size-tiny: 0.5rem;
  --cmp-grid-gutter-size-tiny: calc(var(--spacing-grid-size) * 2);
  --cmp-grid-gutter-size-small: 1rem;
  --cmp-grid-gutter-size-small: calc(var(--spacing-grid-size) * 4);
  --cmp-grid-gutter-size-medium: 1.5rem;
  --cmp-grid-gutter-size-medium: calc(var(--spacing-grid-size) * 6);
  --cmp-grid-gutter-size-large: 2rem;
  --cmp-grid-gutter-size-large: calc(var(--spacing-grid-size) * 8);
}

.ax-grid {
  display: flex;
}

.ax-grid__cell {
  flex: 1 1 0%;
  min-width: 0;
}

.ax-grid--wrap { flex-wrap: wrap; }

.ax-grid--vertical-start  { align-items: flex-start; }
.ax-grid--vertical-middle { align-items: center; }
.ax-grid--vertical-end    { align-items: flex-end; }

.ax-grid--horizontal-start    { justify-content: flex-start; }
.ax-grid--horizontal-middle   { justify-content: center; }
.ax-grid--horizontal-end      { justify-content: flex-end; }
.ax-grid--horizontal-around   { justify-content: space-around; }
.ax-grid--horizontal-between  { justify-content: space-between; }

.ax-grid--columns { flex-direction: column; }

.ax-grid__cell--start  { align-self: flex-start; }
.ax-grid__cell--middle { -ms-grid-row-align: center; align-self: center; }
.ax-grid__cell--end    { align-self: flex-end; }

.ax-grid--gutters-horizontal--tiny   { margin-left: -0.5rem; margin-left: calc(var(--cmp-grid-gutter-size-tiny) * -1); }
.ax-grid--gutters-horizontal--small  { margin-left: -1rem; margin-left: calc(var(--cmp-grid-gutter-size-small) * -1); }
.ax-grid--gutters-horizontal--medium { margin-left: -1.5rem; margin-left: calc(var(--cmp-grid-gutter-size-medium) * -1); }
.ax-grid--gutters-horizontal--large  { margin-left: -2rem; margin-left: calc(var(--cmp-grid-gutter-size-large) * -1); }

.ax-grid--gutters-vertical--tiny   { margin-bottom: -0.5rem; margin-bottom: calc(var(--cmp-grid-gutter-size-tiny) * -1); }
.ax-grid--gutters-vertical--small  { margin-bottom: -1rem; margin-bottom: calc(var(--cmp-grid-gutter-size-small) * -1); }
.ax-grid--gutters-vertical--medium { margin-bottom: -1.5rem; margin-bottom: calc(var(--cmp-grid-gutter-size-medium) * -1); }
.ax-grid--gutters-vertical--large  { margin-bottom: -2rem; margin-bottom: calc(var(--cmp-grid-gutter-size-large) * -1); }

.ax-grid--gutters-horizontal--tiny > .ax-grid__cell   { padding-left: 0.5rem; padding-left: var(--cmp-grid-gutter-size-tiny); }
.ax-grid--gutters-horizontal--small > .ax-grid__cell  { padding-left: 1rem; padding-left: var(--cmp-grid-gutter-size-small); }
.ax-grid--gutters-horizontal--medium > .ax-grid__cell { padding-left: 1.5rem; padding-left: var(--cmp-grid-gutter-size-medium); }
.ax-grid--gutters-horizontal--large > .ax-grid__cell  { padding-left: 2rem; padding-left: var(--cmp-grid-gutter-size-large); }

.ax-grid--gutters-vertical--tiny > .ax-grid__cell   { padding-bottom: 0.5rem; padding-bottom: var(--cmp-grid-gutter-size-tiny); }
.ax-grid--gutters-vertical--small > .ax-grid__cell  { padding-bottom: 1rem; padding-bottom: var(--cmp-grid-gutter-size-small); }
.ax-grid--gutters-vertical--medium > .ax-grid__cell { padding-bottom: 1.5rem; padding-bottom: var(--cmp-grid-gutter-size-medium); }
.ax-grid--gutters-vertical--large > .ax-grid__cell  { padding-bottom: 2rem; padding-bottom: var(--cmp-grid-gutter-size-large); }

.ax-grid__cell--fill    { flex: 1 1 auto; }
.ax-grid__cell--fit     { flex: 1 1 0%; }
.ax-grid__cell--full    { flex: 1 1 100%; }
.ax-grid__cell--none    { flex: 0 0 auto; }
.ax-grid__cell--shrink  { flex: 0 1 auto; }
.ax-grid__cell--sub-grid { display: flex; }

.ax-grid--fill > .ax-grid__cell   { flex: 1 1 auto; }
.ax-grid--fit > .ax-grid__cell    { flex: 1 1 0%; }
.ax-grid--full > .ax-grid__cell   { flex: 1 1 100%; }
.ax-grid--none > .ax-grid__cell   { flex: 0 0 auto; }
.ax-grid--shrink > .ax-grid__cell { flex: 0 1 auto; }

@media (min-width: 48rem) {
  .ax-grid__cell--fill--small    { flex: 1 1 auto; }
  .ax-grid__cell--fit--small     { flex: 1 1 0%; }
  .ax-grid__cell--full--small    { flex: 1 1 100%; }
  .ax-grid__cell--none--small    { flex: 0 0 auto; }
  .ax-grid__cell--shrink--small  { flex: 0 1 auto; }

  .ax-grid--fill--small > .ax-grid__cell   { flex: 1 1 auto; }
  .ax-grid--fit--small > .ax-grid__cell    { flex: 1 1 0%; }
  .ax-grid--full--small > .ax-grid__cell   { flex: 1 1 100%; }
  .ax-grid--none--small > .ax-grid__cell   { flex: 0 0 auto; }
  .ax-grid--shrink--small > .ax-grid__cell { flex: 0 1 auto; }
}

@media (min-width: 62rem) {
  .ax-grid__cell--fill--medium    { flex: 1 1 auto; }
  .ax-grid__cell--fit--medium     { flex: 1 1 0%; }
  .ax-grid__cell--full--medium    { flex: 1 1 100%; }
  .ax-grid__cell--none--medium    { flex: 0 0 auto; }
  .ax-grid__cell--shrink--medium  { flex: 0 1 auto; }

  .ax-grid--fill--medium > .ax-grid__cell   { flex: 1 1 auto; }
  .ax-grid--fit--medium > .ax-grid__cell    { flex: 1 1 0%; }
  .ax-grid--full--medium > .ax-grid__cell   { flex: 1 1 100%; }
  .ax-grid--none--medium > .ax-grid__cell   { flex: 0 0 auto; }
  .ax-grid--shrink--medium > .ax-grid__cell { flex: 0 1 auto; }
}

@media (min-width: 75rem) {
  .ax-grid__cell--fill--large    { flex: 1 1 auto; }
  .ax-grid__cell--fit--large     { flex: 1 1 0%; }
  .ax-grid__cell--full--large    { flex: 1 1 100%; }
  .ax-grid__cell--none--large    { flex: 0 0 auto; }
  .ax-grid__cell--shrink--large  { flex: 0 1 auto; }

  .ax-grid--fill--large > .ax-grid__cell   { flex: 1 1 auto; }
  .ax-grid--fit--large > .ax-grid__cell    { flex: 1 1 0%; }
  .ax-grid--full--large > .ax-grid__cell   { flex: 1 1 100%; }
  .ax-grid--none--large > .ax-grid__cell   { flex: 0 0 auto; }
  .ax-grid--shrink--large > .ax-grid__cell { flex: 0 1 auto; }
}

@media (max-width: 47.9375rem) {
  .ax-grid--responsive > .ax-grid__cell {
    flex: 1 1 100%;
  }
}
