.ax-logo {
  max-width: 100%;
}

/* Update to logo colour vars */
.cls-1 {
  fill: rgb(255, 255, 255);
}

.cls-2 {
  fill: rgb(63, 63, 63);
}

.Anon-3 {
  fill: rgb(87, 183, 221);
}

.Anon-2 {
  fill: rgb(249, 145, 50);
}

.Anon-5 {
  fill: rgb(169, 139, 188);
}

.Anon-4 {
  fill: rgb(255, 109, 86);
}

.Anon-12102 {
  fill: rgb(138, 197, 57);
}

.cls-3 {
  fill: rgb(255, 190, 10);
}
