.ax-progress-finite__background {
  fill: none;
  stroke: rgba(63, 63, 63, .15);
  stroke: var(--color-theme-background--subtle);
}

.ax-progress-finite__arc {
  fill: none;
  stroke: rgb(48, 171, 232);
  stroke: var(--color-ui-accent--hover);
  transition-property: stroke-dashoffset;
  transition-duration: 400ms;
  transition-duration: var(--transition-time-slow);
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition-timing-function: var(--transition-function);
}
